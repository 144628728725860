.user-attention {
  box-sizing: border-box;
  margin-bottom: 20px;
  border: 2px solid #f8d7b1;
  border-radius: 6px;
  background: #fff3e5;
}

.user-attention__block {
  padding: 15px 20px;
}

.user-attention__text {
  font-weight: 600;
  font-size: 15px;
  line-height: 1.3;
  color: #666;

  &:not(:last-of-type) {
    margin-bottom: 15px;
  }

  b {
    font-weight: 700;
    color: #000;
  }
}

.user-attention__name {
  margin-bottom: 15px;
  padding: 7px 0 7px 50px;
  font-weight: 800;
  font-size: 18px;
  line-height: 1.3;
  text-transform: uppercase;
  color: #ff8a00;
  background: url("#{$img-path}/warning.svg") left center no-repeat;
}

.user-attention__info {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding: 20px;
  border-radius: 6px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  background: #fff;
}

.user-attention__left {
  flex-grow: 1;
  padding-right: 20px;
}

.user-attention__right {
  position: relative;
  flex-shrink: 0;
  flex-basis: 200px;
  padding: 15px 0 15px 10px;
  text-align: center;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: -20px;
    width: 1px;
    height: calc(100% + 40px);
    background-color: #ebebeb;
  }

  p {
    &:not(:last-of-type) {
      margin-bottom: 15px;
    }
  }
}

.user-attention__type,
.user-attention__course {
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.3;
  text-transform: uppercase;
}

.user-attention__date {
  font-weight: 600;
  font-size: 15px;
  line-height: 1.3;
  color: #666;

  span {
    font-weight: 700;
    color: #000;
  }
}

.user-attention__discount {
  font-weight: 600;
  font-size: 15px;
  line-height: 1.3;
  color: #666;

  span {
    font-weight: 700;
    color: #f00;
  }
}

.user-attention__price,
.user-attention__term {
  font-weight: 600;
  font-size: 15px;
  line-height: 1.3;
  color: #666;

  .cost {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4px;
    gap: 4px;
  }

  .new {
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
    color: #181818;

    span {
      font-size: 16px;
    }
  }

  .old {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-decoration: line-through;
    color: #ff3b30;
  }

  .discount {
    padding: 2px 5px;
    border-radius: 15px;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #fff;
    background-color: #ff3b30;
  }
}

.user-attention__term {
  text-transform: uppercase;
}

.user-attention__btn {
  display: inline-block;
  padding: 7px 20px;
  border-radius: 4px;
  font-weight: 700;
  font-size: 13px;
  line-height: 1.4;
  text-align: center;
  text-transform: uppercase;
  color: #1987c4;
  background-color: #e0f1fd;
  transition: background-color 0.5s, color 0.5s;

  &:hover {
    color: #1987c4;
    background-color: #b0dbfa;
  }
}

@media (max-width: 768px) {
  .user-attention__info {
    flex-wrap: wrap;
  }

  .user-attention__left {
    flex-basis: 100%;
    padding-right: 0;
  }

  .user-attention__right {
    flex-basis: 100%;
    padding-bottom: 0;
    padding-left: 0;

    &::before {
      display: none;
    }
  }
}

@media (max-width: 480px) {
  .user-attention__name {
    padding-left: 0;
    background: none;
  }
}
