@charset "UTF-8";
.old-browsers {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -999;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  background-color: #000;
  -ms-scroll-chaining: none;
      overscroll-behavior: none;
}
.old-browsers--active {
  z-index: 9999999;
  display: block !important;
}
.old-browsers__center {
  min-height: 100%;
}
.old-browsers__block {
  position: relative;
  margin: 25px auto;
  border-radius: 6px;
  padding: 20px 70px;
  max-width: 600px;
  -webkit-box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
          box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: #fff;
}
.old-browsers__close {
  position: absolute;
  right: 10px;
  top: 10px;
  margin: 0;
  outline: none;
  padding: 0;
  width: 32px;
  height: 32px;
  background: none;
  cursor: pointer;
}
.old-browsers__close:hover {
  opacity: 0.7;
}
.old-browsers__top {
  position: relative;
  border-bottom: 1px solid #ebebeb;
  padding: 30px 0 30px 76px;
}
.old-browsers__top a {
  display: block;
}
.old-browsers__top img {
  position: absolute;
  left: 0;
  top: 35px;
}
.old-browsers__title {
  margin: 0 0 10px;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.4;
  color: #000;
}
.old-browsers__text {
  margin: 10px 0;
  font-size: 16px;
  line-height: 1.375;
  color: #333;
}
.old-browsers__middle {
  overflow: hidden;
  border-bottom: 1px solid #ebebeb;
  padding: 55px 0;
  text-align: center;
}
.old-browsers__left {
  float: left;
}
.old-browsers__right {
  float: right;
}
.old-browsers__left,
.old-browsers__right {
  width: 50%;
}
.old-browsers__left a,
.old-browsers__right a {
  display: block;
}
.old-browsers__link {
  display: block;
  margin: 0 auto;
  border-radius: 2px;
  padding: 6px 0;
  width: 145px;
  -webkit-box-shadow: 0 2px 0 #d6ac01;
          box-shadow: 0 2px 0 #d6ac01;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.35;
  color: #000;
  background: #ffcd00;
  -webkit-transition-property: background-color;
  transition-property: background-color;
  -webkit-transition-duration: 500ms;
          transition-duration: 500ms;
}
.old-browsers__link:hover {
  color: #000;
  background: #ffdc4d;
}
.old-browsers__bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  overflow: hidden;
  margin: 0 10px;
  padding: 15px 0 0;
  font-size: 9px;
  line-height: 1.4;
}
.old-browsers__yandex {
  float: left;
  margin-right: 20px;
  width: 85px;
  text-align: right;
}
.old-browsers__yandex > span {
  display: block;
  font-weight: 700;
}
.old-browsers__yandex > span span {
  color: #f00;
}
.old-browsers__agreement {
  color: #777;
}
.old-browsers__agreement a {
  text-decoration: underline;
  color: #777;
}
.old-browsers__agreement a:hover {
  text-decoration: none;
}
@media (max-width: 680px) {
  .old-browsers__middle {
    padding: 25px 0;
  }

  .old-browsers__top {
    padding-bottom: 40px;
  }

  .old-browsers__block {
    padding: 20px 15px;
    width: calc(100% - 30px);
  }
}
.user-attention {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-bottom: 20px;
  border: 2px solid #f8d7b1;
  border-radius: 6px;
  background: #fff3e5;
}
.user-attention__block {
  padding: 15px 20px;
}
.user-attention__text {
  font-weight: 600;
  font-size: 15px;
  line-height: 1.3;
  color: #666;
}
.user-attention__text:not(:last-of-type) {
  margin-bottom: 15px;
}
.user-attention__text b {
  font-weight: 700;
  color: #000;
}
.user-attention__name {
  margin-bottom: 15px;
  padding: 7px 0 7px 50px;
  font-weight: 800;
  font-size: 18px;
  line-height: 1.3;
  text-transform: uppercase;
  color: #ff8a00;
  background: url("../img/warning.svg") left center no-repeat;
}
.user-attention__info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 15px;
  padding: 20px;
  border-radius: 6px;
  -webkit-box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
          box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  background: #fff;
}
.user-attention__left {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  padding-right: 20px;
}
.user-attention__right {
  position: relative;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -ms-flex-preferred-size: 200px;
      flex-basis: 200px;
  padding: 15px 0 15px 10px;
  text-align: center;
}
.user-attention__right::before {
  content: "";
  position: absolute;
  left: 0;
  top: -20px;
  width: 1px;
  height: calc(100% + 40px);
  background-color: #ebebeb;
}
.user-attention__right p:not(:last-of-type) {
  margin-bottom: 15px;
}
.user-attention__type,
.user-attention__course {
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.3;
  text-transform: uppercase;
}
.user-attention__date {
  font-weight: 600;
  font-size: 15px;
  line-height: 1.3;
  color: #666;
}
.user-attention__date span {
  font-weight: 700;
  color: #000;
}
.user-attention__discount {
  font-weight: 600;
  font-size: 15px;
  line-height: 1.3;
  color: #666;
}
.user-attention__discount span {
  font-weight: 700;
  color: #f00;
}
.user-attention__price,
.user-attention__term {
  font-weight: 600;
  font-size: 15px;
  line-height: 1.3;
  color: #666;
}
.user-attention__price .cost,
.user-attention__term .cost {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 4px;
  gap: 4px;
}
.user-attention__price .new,
.user-attention__term .new {
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  color: #181818;
}
.user-attention__price .new span,
.user-attention__term .new span {
  font-size: 16px;
}
.user-attention__price .old,
.user-attention__term .old {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-decoration: line-through;
  color: #ff3b30;
}
.user-attention__price .discount,
.user-attention__term .discount {
  padding: 2px 5px;
  border-radius: 15px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #fff;
  background-color: #ff3b30;
}
.user-attention__term {
  text-transform: uppercase;
}
.user-attention__btn {
  display: inline-block;
  padding: 7px 20px;
  border-radius: 4px;
  font-weight: 700;
  font-size: 13px;
  line-height: 1.4;
  text-align: center;
  text-transform: uppercase;
  color: #1987c4;
  background-color: #e0f1fd;
  -webkit-transition: background-color 0.5s, color 0.5s;
  transition: background-color 0.5s, color 0.5s;
}
.user-attention__btn:hover {
  color: #1987c4;
  background-color: #b0dbfa;
}
@media (max-width: 768px) {
  .user-attention__info {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }

  .user-attention__left {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    padding-right: 0;
  }

  .user-attention__right {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    padding-bottom: 0;
    padding-left: 0;
  }
  .user-attention__right::before {
    display: none;
  }
}
@media (max-width: 480px) {
  .user-attention__name {
    padding-left: 0;
    background: none;
  }
}
.v-body-library .header {
  margin: 0 0 20px;
  position: relative;
  z-index: 9001;
}
.v-body-library .a-testyng-block {
  position: relative;
}
.v-body-library .st_banner-library__top {
  margin: -20px 0 0;
}
.v-library_filter {
  margin-bottom: 20px;
  border-radius: 4px;
  padding: 30px;
  background-color: #fff;
}
.v-library_filter .v-library-main-title {
  margin-bottom: 16px;
  font-weight: 300;
  font-size: 34px;
  line-height: 1;
  color: #232323;
}
.v-library_filter .v-library-main-title span {
  font-size: 24px;
}
@media (max-width: 992px) {
  .v-library_filter .v-library-main-title {
    font-size: 22px;
  }
  .v-library_filter .v-library-main-title span {
    font-size: 16px;
  }
}
.v-library_filter .v-inline-list-wrap {
  border-radius: 4px;
  padding: 14px 14px 10px;
  background-color: #6e99b2;
}
.v-library_filter .v-inline-list-wrap .v-inline-list li {
  display: inline-block;
  margin-bottom: 4px;
}
.v-library_filter .v-inline-list-wrap .v-inline-list li a {
  display: block;
  padding: 4px 6px;
  line-height: 1;
  color: #e1f4ff;
}
.v-library_filter .v-inline-list-wrap .v-inline-list li a.active {
  border-radius: 4px;
  color: #345263;
  background-color: #fff;
}
@media (max-width: 992px) {
  .v-library_filter .v-select-block {
    margin-bottom: 10px;
  }
}
@media (max-width: 992px) {
  .v-library_filter {
    padding: 10px;
  }
}
.v-library-filter-search {
  position: relative;
  margin-bottom: 20px;
  border: 1px solid #3db9ff;
  border-bottom: 3px solid #3db9ff;
  border-radius: 4px;
  -webkit-box-shadow: 0 10px 15px rgba(0, 135, 212, 0.26);
          box-shadow: 0 10px 15px rgba(0, 135, 212, 0.26);
  background-color: #fff;
}
.v-library-filter-search::before {
  content: "";
  position: absolute;
  left: 22px;
  top: -9px;
  display: block;
  width: 16px;
  height: 9px;
  background: url("../img/library/search-before.png") no-repeat;
}
.v-library-filter-search input[type=search] {
  border: none;
  padding-bottom: 2px;
  padding-left: 12px;
  width: 100%;
  min-height: 42px;
  font-weight: 300;
  font-size: 18px;
  line-height: 1;
  color: #5f89a5;
}
.v-library-filter-search button {
  position: absolute;
  right: 0;
  top: 0;
  border-left: 1px solid #3db9ff;
  padding-top: 4px;
  width: 50px;
  height: 100%;
  background-color: transparent;
}
.v-library-filter-search button span {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: none, url("../img/new_design/sprite.svg") no-repeat;
  background-position: -106px -248px;
}
.v-library_item-info {
  border-bottom: 1px solid #dedede;
  border-radius: 4px 4px 0 0;
  padding: 24px 30px 28px;
  background-color: #fff;
}
.v-library_item-info p {
  font-weight: 300;
  font-size: 26px;
  line-height: 1;
}
@media (max-width: 992px) {
  .v-library_item-info p {
    font-size: 22px;
  }
}
@media (max-width: 992px) {
  .v-library_item-info {
    padding: 10px;
  }
}
.v-library__item {
  position: relative;
  border-bottom: 1px solid #dedede;
}
.v-library__item .wrapper {
  position: relative;
  padding: 30px;
}
@media (max-width: 992px) {
  .v-library__item .wrapper {
    padding: 10px;
  }
}
.v-library__item .v-document-general-info .v-document-general-info__body .v-left-block {
  display: table-cell;
  vertical-align: top;
  width: 60%;
}
.v-library__item .v-document-general-info .v-document-general-info__body .v-left-block .dg-choose-btn {
  display: inline-block;
  margin-bottom: 20px;
  border-radius: 4px;
  padding: 10px 20px;
  color: #fff;
  background-color: #00c8f2;
}
.v-library__item .v-document-general-info .v-document-general-info__body .v-left-block .dg-choose-btn:hover {
  color: #fff;
  background-color: #1ee6ff;
}
.v-library__item .v-document-general-info .v-document-general-info__body .v-left-block .v-document-general-info__title {
  position: relative;
  margin-bottom: 10px;
  font-weight: 300;
  font-size: 22px;
  line-height: 1;
  color: #1987c4;
}
.v-library__item .v-document-general-info .v-document-general-info__body .v-left-block .v-document-general-info__title::before {
  content: "";
  position: relative;
  top: -2px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 4px;
  padding: 0 4px;
  min-width: 42px;
  min-height: 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  background-color: #ccc;
}
@media (max-width: 480px) {
  .v-library__item .v-document-general-info .v-document-general-info__body .v-left-block .v-document-general-info__title {
    font-size: 18px;
  }
}
@media (max-width: 480px) {
  .v-library__item .v-document-general-info .v-document-general-info__body .v-left-block {
    display: block;
    width: 100%;
  }
}
.v-library__item .v-document-general-info .v-document-general-info__body .v-right-block {
  display: table-cell;
  vertical-align: top;
  padding-left: 20px;
  width: 20%;
}
.v-library__item .v-document-general-info .v-document-general-info__body .v-right-block .v-library__cover {
  margin-top: 2px;
}
@media (max-width: 480px) {
  .v-library__item .v-document-general-info .v-document-general-info__body .v-right-block .v-library__cover {
    display: block;
    margin: 20px auto 0;
  }
}
@media (max-width: 480px) {
  .v-library__item .v-document-general-info .v-document-general-info__body .v-right-block {
    display: block;
    padding: 0;
    width: 100%;
  }
}
.v-library__item .v-document-general-info .v-inline-list li {
  display: inline-block;
}
@media (max-width: 480px) {
  .v-library__item .v-document-general-info .v-inline-list li {
    margin: 0 4px 4px 0;
    padding-top: 4px;
  }
}
.v-library__item .v-document-general-info .v-inline-list.icon-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  margin-bottom: 10px;
}
.v-library__item .v-document-general-info .v-inline-list.icon-list li {
  margin-right: 20px;
  color: #888;
}
.v-library__item .v-document-general-info .v-inline-list.icon-list li i {
  margin-right: 4px;
}
.v-library__item .v-document-general-info .v-inline-list.icon-list li a {
  color: #888;
}
.v-library__item .v-document-general-info .v-inline-list.icon-list li a:hover {
  text-decoration: underline;
}
.v-library__item .v-document-general-info .v-inline-list.icon-list li:first-child {
  margin-right: auto;
}
@media (max-width: 480px) {
  .v-library__item .v-document-general-info .v-inline-list.icon-list li:first-child {
    width: 100%;
  }
}
.v-library__item .v-document-general-info .v-inline-list.icon-list li:last-child {
  margin-right: 0;
}
@media (max-width: 480px) {
  .v-library__item .v-document-general-info .v-inline-list.icon-list {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
}
.v-library__item .v-document-general-info .v-inline-list.meta-tags li a {
  display: block;
  border-radius: 4px;
  padding: 5px 12px;
  font-weight: 300;
  font-size: 12px;
  line-height: 1;
  color: #1987c4;
  background-color: #e0f1fd;
  -webkit-transition: 0.3s ease-out;
  transition: 0.3s ease-out;
}
.v-library__item .v-document-general-info .v-inline-list.meta-tags li a:hover {
  color: #e0f1fd;
  background-color: #1987c4;
}
@media (max-width: 480px) {
  .v-library__item .v-document-general-info .v-inline-list.meta-tags li a {
    display: inline-block;
  }
}
.v-library__item:last-child {
  border-bottom: none;
  border-radius: 0 0 4px 4px;
}
.v-library__item:last-child .wrapper {
  border-radius: 0 0 4px 4px;
}
.v-library__item.v-document-format .v-document-general-info .v-document-general-info__body .v-left-block .v-document-general-info__title.v-doc::before {
  content: "doc";
  background-color: #a1d9f9;
}
.v-library__item.v-document-format .v-document-general-info .v-document-general-info__body .v-left-block .v-document-general-info__title.v-docx::before {
  content: "docx";
  background-color: #caccff;
}
.v-library__item.v-document-format .v-document-general-info .v-document-general-info__body .v-left-block .v-document-general-info__title.v-pdf::before {
  content: "pdf";
  background-color: #ffbbb0;
}
.v-library__item.v-document-format .v-document-general-info .v-document-general-info__body .v-left-block .v-document-general-info__title.v-tpl::before {
  content: "tpl";
  background-color: #cfc7b7;
}
.v-library__item.v-document-format .v-document-general-info .v-document-general-info__body .v-left-block .v-document-general-info__title.v-txt::before {
  content: "txt";
  border: 1px solid #b4b4b4;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: #b4b4b4;
  background-color: transparent;
}
.v-library__item.v-document-format .v-document-general-info .v-document-general-info__body .v-left-block .v-document-general-info__title.v-pptx::before {
  content: "pptx";
  background-color: #ffcbae;
}
.v-library__item.v-document-format .v-document-general-info .v-document-general-info__body .v-left-block .v-document-general-info__title.v-ppsx::before {
  content: "ppsx";
  background-color: #ebc2b9;
}
.v-library__item.v-document-format .v-document-general-info .v-document-general-info__body .v-left-block .v-document-general-info__title.v-ppt::before {
  content: "ppt";
  background-color: #f9bbbb;
}
.v-library__item.v-document-format .v-document-general-info .v-document-general-info__body .v-left-block .v-document-general-info__title.v-pptm::before {
  content: "pptm";
  background-color: #f7bfb0;
}
.v-library__item.v-document-format .v-document-general-info .v-document-general-info__body .v-left-block .v-document-general-info__title.v-rar::before {
  content: "rar";
  background-color: #f4c0f1;
}
.v-library__item.v-document-format .v-document-general-info .v-document-general-info__body .v-left-block .v-document-general-info__title.v-7z::before {
  content: "7z";
  background-color: #d6d6d6;
}
.v-library__item.v-document-format .v-document-general-info .v-document-general-info__body .v-left-block .v-document-general-info__title.v-zip::before {
  content: "zip";
  background-color: #fbdc93;
}
.v-library__item.v-document-format .v-document-general-info .v-document-general-info__body .v-left-block .v-document-general-info__title.v-jpg::before {
  content: "jpg";
  background-color: #fadb4b;
}
.v-library__item.v-document-format .v-document-general-info .v-document-general-info__body .v-left-block .v-document-general-info__title.v-bmp::before {
  content: "bmp";
  background-color: #bcd5ff;
}
.v-library__item.v-document-format .v-document-general-info .v-document-general-info__body .v-left-block .v-document-general-info__title.v-png::before {
  content: "png";
  background-color: #abdde8;
}
.v-library__item.v-document-format .v-document-general-info .v-document-general-info__body .v-left-block .v-document-general-info__title.v-psd::before {
  content: "psd";
  background-color: #b7cee2;
}
.v-library__item.v-document-format .v-document-general-info .v-document-general-info__body .v-left-block .v-document-general-info__title.v-avi::before {
  content: "avi";
  background-color: #ffb3b3;
}
.v-library__item.v-document-format .v-document-general-info .v-document-general-info__body .v-left-block .v-document-general-info__title.v-flv::before {
  content: "flv";
  background-color: #e2c3c5;
}
.v-library__item.v-document-format .v-document-general-info .v-document-general-info__body .v-left-block .v-document-general-info__title.v-mp4::before {
  content: "mp4";
  background-color: #f1c5de;
}
.v-library__item.v-document-format .v-document-general-info .v-document-general-info__body .v-left-block .v-document-general-info__title.v-dif::before {
  content: "dif";
  background-color: #bdebb6;
}
.v-library__item.v-document-format .v-document-general-info .v-document-general-info__body .v-left-block .v-document-general-info__title.v-xls::before {
  content: "xls";
  background-color: #cbea81;
}
.v-library__item.v-document-format .v-document-general-info .v-document-general-info__body .v-left-block .v-document-general-info__title.v-xlsx::before {
  content: "xlsx";
  background-color: #a1edc1;
}
.v-library-docs-new {
  border-radius: 4px;
}
.v-library-docs-new .v-library-docs-new-block {
  border-bottom: 1px solid #dedede;
}
.v-library-docs-new .v-library-docs-new-block a .v-library-docs-new-block-cirlce-wrap {
  display: table-cell;
  vertical-align: middle;
}
.v-library-docs-new .v-library-docs-new-block a .v-library-docs-new-block-cirlce-wrap .v-library-docs-new-block-cirlce {
  display: inline-block;
  border-radius: 50%;
  padding: 5px 0 0;
  width: 70px;
  height: 70px;
  text-align: center;
  background-color: #ececec;
}
.v-library-docs-new .v-library-docs-new-block a .v-library-docs-new-block-cirlce-wrap .v-library-docs-new-block-cirlce img {
  display: block;
  margin: auto;
}
.v-library-docs-new .v-library-docs-new-block a span {
  color: #444;
}
.v-library-docs-new .v-library-docs-new-block:first-child a {
  border-radius: 4px 4px 0 0;
}
.v-library-docs-new .v-library-docs-new-block:last-child {
  border-bottom: none;
}
.v-library-docs-new .v-library-docs-new-block:last-child a {
  border-radius: 0 0 4px 4px;
}
.v-body-library .container-fluid {
  padding: 0;
}
.wrap-library {
  margin-bottom: 20px;
  border-radius: 4px;
  background-color: #fff;
}
.wrap-library__flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.wrap-library__right {
  margin: 25px 30px 0 15px;
}
@media (max-width: 990px) {
  .wrap-library__right {
    margin-top: 10px;
  }
}
.wrap-library--relative {
  position: relative;
}
.v-present-item {
  border-top: 1px solid #dedede;
  padding: 20px 30px;
}
.v-present-item .a-namb {
  font-weight: 300;
  font-size: 22px;
  color: #000;
}
.v-present-item .a-namb span {
  display: inline-block;
  border-radius: 50px;
  padding: 0 6px 2px;
  width: auto;
  height: auto;
  line-height: 24px;
  text-align: center;
  color: #000;
  background-color: #ffd200;
}
.v-present-item .a-txt {
  font-size: 14px;
  word-wrap: break-word;
  color: #383838;
}
.wrap-library-inside {
  overflow-wrap: break-word;
  padding: 22px 30px 30px;
}
.wrap-library-inside.wrap-library-inside-similar {
  padding-bottom: 20px;
}
@media (max-width: 992px) {
  .wrap-library-inside {
    padding: 10px;
  }
}
.v-library-new-title {
  padding: 26px 30px 0;
  font-weight: 300;
  font-size: 26px;
  line-height: 1;
}
.v-library-new-title.pb {
  padding-bottom: 26px;
}
@media (max-width: 992px) {
  .v-library-new-title {
    padding: 10px 10px 0;
  }
}
@media (max-width: 480px) {
  .v-library-new-title {
    padding-top: 20px;
    font-size: 18px;
  }
}
.v-interesting-links {
  position: relative;
  display: block;
  margin-bottom: 14px;
  padding-left: 12px;
  font-weight: 300;
  font-size: 18px;
  line-height: 1.2;
  color: #0e75af;
}
.v-interesting-links::before {
  content: "";
  position: absolute;
  left: 0;
  top: 7px;
  display: block;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  background-color: #0e75af;
}
.v-interesting-links:last-child {
  margin-bottom: 0;
}
.v-slider-new {
  margin-bottom: 20px;
  border-radius: 4px;
  -webkit-box-shadow: 0 10px 40px -15px rgba(0, 0, 0, 0.5);
          box-shadow: 0 10px 40px -15px rgba(0, 0, 0, 0.5);
}
.v-slider-new .v-slider-header {
  border-radius: 4px 4px 0 0;
  padding: 20px;
  background-image: -webkit-gradient(linear, left top, right top, from(#55c709), color-stop(32.5%, #86c71b), color-stop(50%, #86c71b), color-stop(67.5%, #86c71b), to(#55c709));
  background-image: linear-gradient(to right, #55c709 0%, #86c71b 32.5%, #86c71b 50%, #86c71b 67.5%, #55c709 100%);
  background-color: #55c709;
}
.v-slider-new .v-slider-header .kr-shared-block {
  float: right;
}
.v-slider-new .v-slider-header .kr-shared-block .kr-shared-one {
  vertical-align: middle;
}
.v-slider-new .v-slider-header .kr-shared-block .kr-shared-one .kr-shared {
  vertical-align: middle;
  width: 29px;
  height: 29px;
  background: none, url("../img/new_design/sprite.svg") no-repeat -207px -213px;
  background-size: inherit;
}
.v-slider-new .v-slider-header .kr-shared-block .kr-shared-one .a-b-b-sh {
  display: inline-block;
  vertical-align: middle;
}
.v-slider-new .v-slider-header .kr-shared-block .kr-shared-one .a-b-b-sh .a-shar {
  margin: 0;
  text-align: left;
}
.v-slider-new .v-slider-header .kr-shared-block .kr-shared-one .a-shar a {
  margin-left: 2px;
}
.v-slider-new .v-slider-header .kr-shared-block .kr-shared-one .a-shar a .kr-social-icons {
  width: 29px;
  height: 29px;
  background: none, url("../img/new_design/sprite.svg") no-repeat;
  background-size: inherit;
  opacity: 1;
}
.v-slider-new .v-slider-header .kr-shared-block .kr-shared-one .a-shar a .kr-social-icons.kr-vk {
  background-position: -137px -248px;
}
.v-slider-new .v-slider-header .kr-shared-block .kr-shared-one .a-shar a .kr-social-icons.kr-ok {
  background-position: -171px -248px;
}
.v-slider-new .v-slider-header .kr-shared-block .kr-shared-one .a-shar a .kr-social-icons.kr-f {
  background-position: -207px -248px;
}
.v-slider-new .v-slider-header .kr-shared-block .kr-shared-one .a-shar a .kr-social-icons.kr-m {
  background-position: -242px -248px;
}
.v-slider-new .v-slider-header .kr-shared-block .kr-shared-one .a-shar a:hover {
  opacity: 0.8;
}
.v-slider-new .v-slider-header .kr-logo-with-descr .a-logo {
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
  width: 188px;
  height: 27px;
  background: none, url("../img/new_design/logo.svg") no-repeat;
}
.v-slider-new .v-slider-header .kr-logo-with-descr .kr-logo-descriptor {
  display: inline-block;
  vertical-align: middle;
  font-size: 12px;
  line-height: 1.2;
  color: #fff;
}
@media (max-width: 992px) {
  .v-slider-new .v-slider-header .kr-logo-with-descr {
    margin-bottom: 10px;
  }
}
@media (max-width: 992px) {
  .v-slider-new .v-slider-header {
    text-align: center;
  }
  .v-slider-new .v-slider-header .kr-shared-block {
    float: none;
  }
}
.v-slider-new .v-slider-body {
  padding: 10px 4px 10px 0;
}
.v-slider-new .v-slider-body .preview {
  padding: 20px;
}
.v-slider-new .v-slider-body .preview::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.v-slider-new .v-slider-body .preview::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #68b300;
}
.v-slider-new .v-slider-body .preview::-webkit-scrollbar-track {
  border-radius: 4px;
  -webkit-box-shadow: inset 0 2px 10px rgba(0, 0, 0, 0.3);
          box-shadow: inset 0 2px 10px rgba(0, 0, 0, 0.3);
}
@media (max-width: 991px) {
  .v-slider-new .v-slider-body .preview {
    padding: 10px;
  }
}
.v-slider-new .v-slider-body .a-slider-1 {
  position: relative;
  overflow: hidden;
  height: auto;
}
.v-slider-new .v-slider-body .a-slider-1 .slide {
  overflow: hidden;
  width: 100%;
  height: 0;
  text-align: center;
}
.v-slider-new .v-slider-body .a-slider-1 .slide.show {
  position: relative;
  z-index: 1;
  height: auto;
  min-height: 50px;
}
.v-slider-new .v-slider-body .a-slider-1 img {
  display: block;
  margin: auto;
  border: 0;
  width: 100%;
  height: auto;
  max-width: 100%;
}
.v-slider-new .v-slider-body .a-slider-1 .a-pointly {
  position: absolute;
  top: 0;
  z-index: 99;
  display: block;
  height: 100%;
  background: 0;
  opacity: 0;
}
.v-slider-new .v-slider-body .a-slider-1 .a-pointly.a-leftpoint {
  left: 0;
  width: 40%;
  cursor: pointer;
}
.v-slider-new .v-slider-body .a-slider-1 .a-pointly.a-rightpoint {
  right: 0;
  width: 40%;
  cursor: pointer;
}
.v-slider-new .v-slider-body.v-slider-body-ppt {
  padding: 0;
}
.v-slider-new .v-slider-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 0 0 4px 4px;
  padding: 20px 20px 18px;
  background-image: -webkit-gradient(linear, left top, right top, from(#eceae1), color-stop(50%, #eceae1), to(#eceae1));
  background-image: linear-gradient(to right, #eceae1 0%, #eceae1 50%, #eceae1 100%);
  background-color: #e1e4d5;
}
.v-slider-new .v-slider-footer .a-pult {
  float: left;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.v-slider-new .v-slider-footer .a-pult .a-page {
  display: inline-block;
}
.v-slider-new .v-slider-footer .a-pult .v-button-doc-player {
  display: inline-block;
  border-radius: 4px;
  padding-top: 12px;
  width: 79px;
  height: 39px;
  -webkit-box-shadow: 0 2px 0 rgba(91, 151, 191, 0.7);
          box-shadow: 0 2px 0 rgba(91, 151, 191, 0.7);
  text-align: center;
  background-color: #87d0ff;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.v-slider-new .v-slider-footer .a-pult .v-button-doc-player i {
  display: inline-block;
  width: 26px;
  height: 16px;
  background: none, url("../img/new_design/sprite.svg") no-repeat;
}
.v-slider-new .v-slider-footer .a-pult .v-button-doc-player i.a-bac-top {
  background-position: -107px -292px;
}
.v-slider-new .v-slider-footer .a-pult .v-button-doc-player i.a-for-boot {
  background-position: -107px -292px;
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
.v-slider-new .v-slider-footer .a-pult .v-button-doc-player:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
@media (max-width: 990px) {
  .v-slider-new .v-slider-footer .a-pult {
    margin-right: 20px;
  }
}
@media (max-width: 580px) {
  .v-slider-new .v-slider-footer .a-pult {
    margin-right: 0;
    margin-bottom: 25px;
  }
}
.v-slider-new .v-slider-footer .v-give-code {
  position: relative;
  float: right;
  border-radius: 4px;
  padding: 6px 20px 6px 60px;
  width: 209px;
  -webkit-box-shadow: 0 2px 0 rgba(148, 158, 128, 0.1);
          box-shadow: 0 2px 0 rgba(148, 158, 128, 0.1);
  font-weight: 300;
  font-size: 20px;
  color: #718898;
  background-color: #fff;
}
.v-slider-new .v-slider-footer .v-give-code i {
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  border-radius: 4px 0 0 4px;
  width: 44px;
  height: 39px;
  background: #d3d3c1 url("../img/new_design/sprite.svg") no-repeat -218px -282px;
}
.v-slider-new .v-slider-footer.v-slider-footer-ppt .a-pult {
  float: left;
}
.v-slider-new .v-slider-footer.v-slider-footer-ppt .a-pult .a-fast-backward,
.v-slider-new .v-slider-footer.v-slider-footer-ppt .a-pult .a-fast-forward {
  display: inline-block;
  vertical-align: middle;
  border-radius: 4px;
  padding: 4px 4px 0;
  width: unset;
  height: unset;
  -webkit-box-shadow: 0 2px 0 rgba(91, 151, 191, 0.7);
          box-shadow: 0 2px 0 rgba(91, 151, 191, 0.7);
  text-align: center;
  background-color: rgba(135, 208, 255, 0.3);
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.v-slider-new .v-slider-footer.v-slider-footer-ppt .a-pult .a-fast-backward i,
.v-slider-new .v-slider-footer.v-slider-footer-ppt .a-pult .a-fast-forward i {
  display: inline-block;
  width: 32px;
  height: 27px;
  background: none, url("../img/new_design/sprite.svg") no-repeat;
}
@media (max-width: 480px) {
  .v-slider-new .v-slider-footer.v-slider-footer-ppt .a-pult .a-fast-backward,
.v-slider-new .v-slider-footer.v-slider-footer-ppt .a-pult .a-fast-forward {
    width: 40px;
  }
}
.v-slider-new .v-slider-footer.v-slider-footer-ppt .a-pult .a-fast-backward i {
  background-position: -144px -293px;
}
.v-slider-new .v-slider-footer.v-slider-footer-ppt .a-pult .a-fast-forward i {
  background-position: -144px -293px;
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
.v-slider-new .v-slider-footer.v-slider-footer-ppt .a-pult .a-backward,
.v-slider-new .v-slider-footer.v-slider-footer-ppt .a-pult .a-backward-l {
  display: inline-block;
  vertical-align: middle;
  border-radius: 4px;
  padding: 4px 4px 0;
  width: unset;
  height: unset;
  -webkit-box-shadow: 0 2px 0 rgba(91, 151, 191, 0.7);
          box-shadow: 0 2px 0 rgba(91, 151, 191, 0.7);
  text-align: center;
  background-color: #87d0ff;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.v-slider-new .v-slider-footer.v-slider-footer-ppt .a-pult .a-backward i,
.v-slider-new .v-slider-footer.v-slider-footer-ppt .a-pult .a-backward-l i {
  display: inline-block;
  width: 16px;
  height: 27px;
  background: none, url("../img/new_design/sprite.svg") no-repeat;
}
@media (max-width: 480px) {
  .v-slider-new .v-slider-footer.v-slider-footer-ppt .a-pult .a-backward,
.v-slider-new .v-slider-footer.v-slider-footer-ppt .a-pult .a-backward-l {
    width: 32px;
  }
}
.v-slider-new .v-slider-footer.v-slider-footer-ppt .a-pult .a-backward i {
  background-position: -191px -293px;
}
.v-slider-new .v-slider-footer.v-slider-footer-ppt .a-pult .a-backward-l i {
  background-position: -191px -293px;
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
.v-slider-new .v-slider-footer.v-slider-footer-ppt .a-pult .a-page {
  display: inline-block;
  vertical-align: middle;
  margin: 0 16px;
  font-size: 24px;
}
.v-slider-new .v-slider-footer.v-slider-footer-ppt .a-pult .a-page .a-pages {
  font-weight: 700;
}
.v-slider-new .v-slider-footer.v-slider-footer-ppt .a-pult .a-page .a-dalee {
  font-weight: 300;
  color: #afafaf;
}
@media (max-width: 480px) {
  .v-slider-new .v-slider-footer.v-slider-footer-ppt .a-pult .a-page {
    margin: 0 9px;
  }
}
@media (max-width: 580px) {
  .v-slider-new .v-slider-footer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
@media (max-width: 480px) {
  .v-slider-new .v-slider-footer {
    padding: 10px;
  }
}
.v-slider-new .v-slider-wrap .v-slider-bottom-text {
  margin-top: 14px;
  font-weight: 300;
  font-size: 18px;
  line-height: 1;
  text-align: center;
  color: #595959;
}
.v-slider-new .v-slider-wrap .v-slider-bottom-text b {
  display: inline-block;
  border-radius: 4px;
  padding: 2px 6px;
  font-weight: 600;
  font-size: 20px;
  line-height: 1;
  text-align: center;
  color: #fff;
  background-color: rgba(120, 150, 167, 0.7);
}
.v-slider-new .v-slider-wrap .v-slider-bottom-text span {
  font-weight: 300;
  font-size: 14px;
  color: #595959;
}
@media (max-width: 992px) {
  .v-slider-new .v-slider-wrap .v-slider-bottom-text {
    font-size: 16px;
  }
}
@media (max-width: 992px) {
  .v-slider-new .v-slider-wrap {
    padding: 0 10px 10px;
  }
}
.v-library-commment .a-u-s {
  border: none;
  padding: 22px 30px;
}
.v-library-commment .a-u-s p.a-note {
  margin-bottom: 24px;
  font-weight: 300;
  font-size: 26px;
  line-height: 1;
  color: #000;
}
.v-library-commment .a-u-s p.a-note span {
  margin: 0;
  font-size: 16px;
  color: #7e7e7e;
}
.v-library-commment .a-u-s .aa-comment-form .redactor-editor,
.v-library-commment .a-u-s .aa-comment-form .redactor-box {
  border-radius: 4px;
  background-color: #f5f5f5;
}
.v-library-commment .a-u-s .aa-comment-form .redactor-editor .redactor-toolbar,
.v-library-commment .a-u-s .aa-comment-form .redactor-box .redactor-toolbar {
  border-bottom: 1px solid #eee;
  border-radius: 4px 4px 0 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  background-color: #f5f5f5;
}
.v-library-commment .a-u-s .aa-comment-form .redactor-editor .redactor-toolbar li a,
.v-library-commment .a-u-s .aa-comment-form .redactor-box .redactor-toolbar li a {
  background: none;
}
.v-library-commment .a-u-s .aa-comment-form .redactor-editor .redactor-toolbar li a:hover,
.v-library-commment .a-u-s .aa-comment-form .redactor-box .redactor-toolbar li a:hover {
  color: #1987c4;
}
.v-library-commment .a-u-s .aa-comment-form .redactor-editor .redactor-editor,
.v-library-commment .a-u-s .aa-comment-form .redactor-box .redactor-editor {
  border: none;
  padding: 16px !important;
}
@media (max-width: 992px) {
  .v-library-commment .a-u-s {
    padding: 10px;
  }
}
.v-library-commment .aa-comment-list {
  margin-right: -30px;
  margin-left: -30px;
}
.v-library-commment .aa-comment-list .a-comm {
  margin: 0;
  border-top: 1px solid #dedede;
  border-radius: 0;
  padding: 14px 30px;
  background: none;
}
.v-library-commment .aa-comment-list .a-comm .a-comm1::after {
  content: "";
  display: block;
  clear: both;
}
.v-library-commment .aa-comment-list .a-comm .a-comm1 .dr-author {
  float: left;
}
.v-library-commment .aa-comment-list .a-comm .a-comm1 .dr-author b {
  font-weight: 300;
}
.v-library-commment .aa-comment-list .a-comm .a-comm1 .dr-author b span {
  font-size: 22px;
  line-height: 1;
  color: #888;
}
.v-library-commment .aa-comment-list .a-comm .a-comm1 .dr-author .kr-black-white-icons.kr-profile {
  top: -1px;
  width: 16px;
  height: 16px;
  background: none, url("../img/new_design/sprite.svg") no-repeat -66px -143px;
}
@media (max-width: 992px) {
  .v-library-commment .aa-comment-list .a-comm .a-comm1 .dr-author {
    display: block;
    float: none;
    margin-bottom: 6px;
  }
  .v-library-commment .aa-comment-list .a-comm .a-comm1 .dr-author b span {
    font-size: 14px;
  }
}
.v-library-commment .aa-comment-list .a-comm .a-comm1 .v-right-date {
  float: right;
  color: #898989;
}
.v-library-commment .aa-comment-list .a-comm .a-comm1 .v-right-date .kr-black-white-icons.kr-material-date {
  top: -1px;
  width: 16px;
  height: 16px;
  background: none, url("../img/new_design/sprite.svg") no-repeat -66px -165px;
}
@media (max-width: 992px) {
  .v-library-commment .aa-comment-list .a-comm .a-comm1 .v-right-date {
    float: none;
  }
}
.v-library-commment .aa-comment-list .a-comm:last-child {
  padding-bottom: 0;
}
@media (max-width: 992px) {
  .v-library-commment .aa-comment-list .a-comm {
    padding: 10px;
  }
}
@media (max-width: 992px) {
  .v-library-commment .aa-comment-list {
    margin: 0;
  }
}
.v-library-document h1 {
  margin: 0 0 20px;
  border-radius: 4px 4px 0 0;
  padding: 26px 30px;
  font-weight: 600;
  font-size: 32px;
  line-height: 1;
  text-align: left;
  color: #37474f;
  background-color: #fff;
}
@media (max-width: 992px) {
  .v-library-document h1 {
    padding: 25px 10px 10px;
  }
}
@media (max-width: 480px) {
  .v-library-document h1 {
    font-size: 29px;
  }
}
.v-library-document h1.v-library-document__title {
  padding: 0;
  line-height: 1.2;
  word-break: break-word;
  text-align: left;
  background-color: unset;
}
.v-library-document h1.v-library-document__title:last-child {
  margin-bottom: 0;
}
.v-library-document .dg-wrapper__alert {
  border-bottom: none;
  border-radius: 4px;
}
.v-new-general-info .v-library__item {
  border-bottom: none;
}
.v-new-general-info .v-library__item .v-document-general-info .kr-document-general-info__header {
  border-radius: 4px;
  padding: 8px 10px;
  background-color: #f5f5f5;
}
.v-new-general-info .v-library__item .v-document-general-info .v-inline-list.icon-list {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0;
}
.v-new-general-info .v-library__item .v-document-general-info .v-inline-list.icon-list li.v-author-inside a {
  font-weight: 300;
  font-size: 18px;
  color: #000;
}
.v-new-general-info .v-library__item .v-document-general-info .v-inline-list.meta-tags li {
  margin-bottom: 4px;
  border: none;
  background: none;
}
.v-new-general-info .v-btn-give-sert {
  display: inline-block;
  border-radius: 4px;
  padding: 4px 12px;
  font-weight: 300;
  line-height: 1;
  text-align: center;
  color: #fff;
  background-color: #5b97bf;
}
.ta-docSearch-blck:last-child {
  margin-right: 0;
}
@media screen and (min-width: 992px) and (max-width: 1227px) {
  .ta-docSearch-blck:nth-child(2n) {
    margin-right: 0;
  }
}
@media screen and (min-width: 1227px) {
  .ta-docSearch-blck {
    float: left;
    margin-right: 1.62757%;
    width: 23.77932%;
  }
}
@media screen and (min-width: 992px) and (max-width: 1227px) {
  .ta-docSearch-blck {
    float: left;
    margin-right: 1.62757%;
    margin-bottom: 10px;
    width: 49.18622%;
  }
}
.ta-related-docs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  /* stylelint-disable */
  /* stylelint-enable */
}
.ta-related-docs__block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-bottom: 10px;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  padding: 16px;
  width: 100%;
  word-break: break-word;
}
@media screen and (min-width: 801px) {
  .ta-related-docs__block:nth-child(3n) {
    margin-right: 0;
  }
}
.ta-related-docs__block.ta-related-dop {
  padding: 12px;
  text-align: center;
}
.ta-related-docs__block.ta-related-dop a {
  display: block;
  margin-top: 4px;
  text-decoration: underline;
  color: #555;
}
.ta-related-docs__block.ta-related-dop a img {
  display: block;
  margin: 6px auto 0;
  width: 22px;
  height: 22px;
}
.ta-related-docs__block.ta-related-dop a:hover {
  text-decoration: none;
}
@media screen and (min-width: 801px) {
  .ta-related-docs__block {
    margin-right: 1.69492%;
    width: 32.20339%;
    min-height: 150px;
  }
}
.ta-related-docs--info {
  margin-top: auto;
}
.ta-related-docs--info li {
  display: inline-block;
}
.ta-related-docs--title {
  margin-bottom: 10px;
  font-weight: 300;
  font-size: 16px;
  color: #1987c4;
}
@supports (display: -webkit-box) {
  .ta-related-docs--title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
  }
}
.v-similar-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.v-similar-wrap .v-similar {
  overflow: auto;
  margin-bottom: 10px;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  padding: 15px;
  width: 100%;
  min-height: 133px;
  background-color: #fff;
}
.v-similar-wrap .v-similar .kr-recently-viewed__item {
  margin: 0;
}
.v-similar-wrap .v-similar .kr-sidebar-block__content-title {
  overflow: hidden;
  min-height: 66px;
  max-height: 66px;
}
.v-similar-wrap .v-similar .kr-sidebar-block__content-title span {
  font-weight: 300;
  font-size: 16px;
  color: #1987c4;
}
.v-similar-wrap .v-similar .kr-list li {
  display: inline-block;
}
@media screen and (min-width: 801px) {
  .v-similar-wrap .v-similar:nth-child(3n) {
    margin-right: 0;
  }
}
.v-similar-wrap .v-similar.v-similar-dop {
  padding: 12px 0 11px;
  text-align: center;
}
.v-similar-wrap .v-similar.v-similar-dop a {
  display: block;
  margin-top: 4px;
  text-decoration: underline;
  color: #555;
}
.v-similar-wrap .v-similar.v-similar-dop a img {
  display: block;
  margin: 6px auto 0;
  width: 22px;
  height: 22px;
}
.v-similar-wrap .v-similar.v-similar-dop a:hover {
  text-decoration: none;
}
@media screen and (min-width: 801px) {
  .v-similar-wrap .v-similar {
    float: left;
    margin-right: 1.69492%;
    width: 32.20339%;
  }
}
.v-similar-wrap .dr-col-4 {
  margin-bottom: 10px;
}
.v-similar-wrap .dr-col-4:nth-child(3n) {
  margin-right: 0;
}
.library_button_download {
  margin-bottom: 20px;
}
.v-btn-download {
  position: relative;
  display: inline-block;
  margin: 0 auto;
  border-radius: 4px;
  padding: 6px 20px;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4;
  text-transform: uppercase;
  color: #fff;
  background-color: #72bb4c;
}
.v-btn-download i {
  display: none;
}
.v-btn-download i.v-icon-download {
  background: none, url("../img/library/library-download.svg") no-repeat;
}
.v-btn-download:hover {
  text-decoration: none;
  color: #fff;
  background-color: #52d65c;
}
.dg-download-lnk {
  display: inline-block;
  font-size: 18px;
  color: #999;
}
.dg-download-lnk__wrapper {
  margin: -16px 0 6px;
  text-align: center;
}
.dg-download-lnk:hover {
  text-decoration: underline;
  color: #999;
}
.v-button-blue {
  display: inline-block;
  margin-bottom: 14px;
  border-radius: 4px;
  padding: 6px 10px 8px;
  font-weight: 300;
  font-size: 22px;
  line-height: 1;
  color: #fff;
  background-color: #5b97bf;
  -webkit-transition: 0.3s ease-out;
  transition: 0.3s ease-out;
}
.v-button-blue:hover {
  color: #fff;
  background-color: #1987c4;
}
.v-search-inside {
  border-bottom: 1px solid #dedede;
  border-radius: 4px;
  padding: 12px 30px 30px;
  background-color: #fff;
}
.v-search-inside .v-library-filter-search {
  margin-bottom: 0;
}
.v-search-inside.v-not-bd-btm {
  border-radius: 4px 4px 0 0;
}
.v-search-inside-title {
  margin-bottom: 16px;
  font-weight: 300;
  font-size: 24px;
  line-height: 1;
  color: #6b6b6b;
}
.v-doc-arch .v-doc-arch-item:hover {
  border-color: #6e99b2;
  background-color: #6e99b2;
}
.v-doc-arch .v-doc-arch-item:hover span {
  color: #fff !important;
}
.v-doc-arch .v-doc-arch-item:hover i {
  opacity: 1;
}
.v-doc-arch .v-doc-arch-item {
  margin-bottom: 10px;
  border: 1px solid #e8e8e8;
  border-radius: 6px;
  padding: 10px;
  line-height: 1;
  background-color: #fff;
}
.v-doc-arch .v-doc-arch-item .a-span {
  float: right;
  padding: 4px 2px 0 16px;
  font-size: 12px;
  color: #8a8a8a;
}
.v-doc-arch .v-doc-arch-item .a-span strong {
  font-weight: 300;
  line-height: 1;
}
.v-doc-arch .v-doc-arch-item .dr-block-overflow {
  vertical-align: middle;
  overflow: inherit;
  font-weight: 300;
  font-size: 14px;
  color: #37627a;
}
.v-doc-arch .v-doc-arch-item article i {
  position: relative;
  margin-left: 4px;
  border-radius: 4px;
  width: 43px;
  height: 19px;
  min-height: 20px;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  line-height: 21px;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  background-color: #ccc;
  opacity: 0.8;
}
.v-doc-arch .v-doc-arch-item article i.a-doc {
  background: #a1d9f9;
}
.v-doc-arch .v-doc-arch-item article i.a-doc::before {
  content: "doc";
}
.v-doc-arch .v-doc-arch-item article i.a-docx {
  background: #caccff;
}
.v-doc-arch .v-doc-arch-item article i.a-docx::before {
  content: "docx";
}
.v-doc-arch .v-doc-arch-item article i.a-pdf {
  background: #ffbbb0;
}
.v-doc-arch .v-doc-arch-item article i.a-pdf::before {
  content: "pdf";
}
.v-doc-arch .v-doc-arch-item article i.a-tpl {
  background: #cfc7b7;
}
.v-doc-arch .v-doc-arch-item article i.a-tpl::before {
  content: "tpl";
}
.v-doc-arch .v-doc-arch-item article i.a-txt {
  border: 1px solid #b4b4b4;
  background: transparent;
}
.v-doc-arch .v-doc-arch-item article i.a-txt::before {
  content: "txt";
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: #b4b4b4;
}
.v-doc-arch .v-doc-arch-item article i.a-pptx {
  background: #ffcbae;
}
.v-doc-arch .v-doc-arch-item article i.a-pptx::before {
  content: "pptx";
}
.v-doc-arch .v-doc-arch-item article i.a-ppsx {
  background: #ebc2b9;
}
.v-doc-arch .v-doc-arch-item article i.a-ppsx::before {
  content: "ppsx";
}
.v-doc-arch .v-doc-arch-item article i.a-ppt {
  background: #f9bbbb;
}
.v-doc-arch .v-doc-arch-item article i.a-ppt::before {
  content: "ppt";
}
.v-doc-arch .v-doc-arch-item article i.a-pptm {
  background: #f7bfb0;
}
.v-doc-arch .v-doc-arch-item article i.a-pptm::before {
  content: "pptm";
}
.v-doc-arch .v-doc-arch-item article i.a-rar {
  background: #f4c0f1;
}
.v-doc-arch .v-doc-arch-item article i.a-rar::before {
  content: "rar";
}
.v-doc-arch .v-doc-arch-item article i.a-7z {
  background: #d6d6d6;
}
.v-doc-arch .v-doc-arch-item article i.a-7z::before {
  content: "7z";
}
.v-doc-arch .v-doc-arch-item article i.a-zip {
  background: #fbdc93;
}
.v-doc-arch .v-doc-arch-item article i.a-zip::before {
  content: "zip";
}
.v-doc-arch .v-doc-arch-item article i.a-jpg {
  background: #fadb4b;
}
.v-doc-arch .v-doc-arch-item article i.a-jpg::before {
  content: "jpg";
}
.v-doc-arch .v-doc-arch-item article i.a-bmp {
  background: #bcd5ff;
}
.v-doc-arch .v-doc-arch-item article i.a-bmp::before {
  content: "bmp";
}
.v-doc-arch .v-doc-arch-item article i.a-png {
  background: #abdde8;
}
.v-doc-arch .v-doc-arch-item article i.a-png::before {
  content: "png";
}
.v-doc-arch .v-doc-arch-item article i.a-psd {
  background: #b7cee2;
}
.v-doc-arch .v-doc-arch-item article i.a-psd::before {
  content: "psd";
}
.v-doc-arch .v-doc-arch-item article i.a-avi {
  background: #ffb3b3;
}
.v-doc-arch .v-doc-arch-item article i.a-avi::before {
  content: "avi";
}
.v-doc-arch .v-doc-arch-item article i.a-flv {
  background: #e2c3c5;
}
.v-doc-arch .v-doc-arch-item article i.a-flv::before {
  content: "flv";
}
.v-doc-arch .v-doc-arch-item article i.a-mp4 {
  background: #f1c5de;
}
.v-doc-arch .v-doc-arch-item article i.a-mp4::before {
  content: "mp4";
}
.v-doc-arch .v-doc-arch-item article i.a-dif {
  background: #bdebb6;
}
.v-doc-arch .v-doc-arch-item article i.a-dif::before {
  content: "dif";
}
.v-doc-arch .v-doc-arch-item article i.a-xls {
  background: #cbea81;
}
.v-doc-arch .v-doc-arch-item article i.a-xls::before {
  content: "xls";
}
.v-doc-arch .v-doc-arch-item article i.a-xlsx {
  background: #a1edc1;
}
.v-doc-arch .v-doc-arch-item article i.a-xlsx::before {
  content: "xlsx";
}
.v-file-choose {
  margin-bottom: 20px;
  border-radius: 4px 4px 0 0;
  padding: 26px 30px;
  font-weight: 300;
  line-height: 1;
  color: #232323;
  background-color: #fff;
}
.v-file-choose strong {
  display: block;
  margin-bottom: 4px;
}
.v-wb-bw {
  font-weight: 300;
}
.v-wb-bw ul li {
  margin-bottom: 10px;
  font-size: 18px;
}
.v-wb-bw ul li:last-child {
  margin-bottom: 0;
}
.v-wb-bw p {
  margin-bottom: 10px;
  font-size: 18px;
}
.v-wb-bw p:last-child {
  margin-bottom: 0;
}
.v-top-side-new-library {
  margin: -10px 0 20px;
  padding-bottom: 40px;
  background: #1a4f6e url("../img/library/bottom-heade.png");
}
.v-top-side-new-library .v-top-side-new-library-header {
  margin: 0 0 30px;
  padding: 38px 0;
  background: url("../img/library/top-head.png"), -webkit-gradient(linear, left top, right top, from(#009387), to(#008cb1));
  background: url("../img/library/top-head.png"), linear-gradient(to right, #009387 0%, #008cb1 100%);
}
.v-top-side-new-library .v-top-side-new-library-header .v-top-side-new-title {
  font-weight: 700;
  font-size: 56px;
  line-height: 0.9;
  color: #fff;
}
.v-top-side-new-library .v-top-side-new-library-header .v-top-side-new-title span {
  display: block;
  font-weight: 300;
  font-size: 40px;
}
.v-top-side-new-library .v-top-side-new-library-header .v-top-side-new-counter-wrap {
  border-radius: 4px;
  padding: 20px;
  background-image: linear-gradient(45deg, #00b7a8 0%, #00a9d5 100%);
}
.v-top-side-new-library .v-top-side-new-library-header .v-top-side-new-counter-wrap .v-top-side-new-counter,
.v-top-side-new-library .v-top-side-new-library-header .v-top-side-new-counter-wrap .v-top-side-new-counter-text {
  display: inline-block;
  vertical-align: middle;
}
.v-top-side-new-library .v-top-side-new-library-header .v-top-side-new-counter-wrap .v-top-side-new-counter {
  margin-right: 10px;
}
.v-top-side-new-library .v-top-side-new-library-header .v-top-side-new-counter-wrap .v-top-side-new-counter span {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-right: 4px;
  border-radius: 4px;
  padding: 4px 6px 5px;
  font-weight: 300;
  font-size: 40px;
  line-height: 1;
  text-shadow: 0 2px 0 rgba(186, 104, 0, 0.5);
  color: #fff;
  background-image: linear-gradient(45deg, #ff7800 0%, #ffc000 100%);
  background-color: #fffcc5;
}
.v-top-side-new-library .v-top-side-new-library-header .v-top-side-new-counter-wrap .v-top-side-new-counter span:last-child {
  margin-right: 0;
}
.v-top-side-new-library .v-top-side-new-library-header .v-top-side-new-counter-wrap .v-top-side-new-counter span.v-top-side-new-counter-dot {
  margin-right: 13px;
}
.v-top-side-new-library .v-top-side-new-library-header .v-top-side-new-counter-wrap .v-top-side-new-counter span.v-top-side-new-counter-dot::before {
  content: "";
  position: absolute;
  right: -8px;
  bottom: 9px;
  display: block;
  border-radius: 50%;
  width: 4px;
  height: 4px;
  background-image: linear-gradient(45deg, #ff9c00 0%, #ffd200 100%);
  background-color: #fffcc5;
}
@media (max-width: 480px) {
  .v-top-side-new-library .v-top-side-new-library-header .v-top-side-new-counter-wrap .v-top-side-new-counter {
    margin-right: 0;
  }
  .v-top-side-new-library .v-top-side-new-library-header .v-top-side-new-counter-wrap .v-top-side-new-counter span {
    padding: 2px;
  }
}
.v-top-side-new-library .v-top-side-new-library-header .v-top-side-new-counter-wrap .v-top-side-new-counter-text {
  font-weight: 300;
  font-size: 18px;
  color: #fff;
}
.v-top-side-new-library .v-top-side-new-library-header .v-top-side-new-counter-wrap .v-top-side-new-counter-text span {
  display: inline-block;
  border-bottom: 1px solid #dccd25;
  padding: 0 2px;
  background-image: -webkit-gradient(linear, left top, right top, from(#00bd16), to(#70d615));
  background-image: linear-gradient(to right, #00bd16 0%, #70d615 100%);
  background-color: #ff9000;
}
@media (max-width: 480px) {
  .v-top-side-new-library .v-top-side-new-library-header .v-top-side-new-counter-wrap .v-top-side-new-counter-text {
    font-size: 26px;
  }
}
@media (max-width: 992px) {
  .v-top-side-new-library .v-top-side-new-library-header {
    margin-bottom: 14px;
    padding: 20px 0;
    text-align: center;
  }
  .v-top-side-new-library .v-top-side-new-library-header .v-top-side-new-title {
    margin-bottom: 14px;
    font-size: 40px;
  }
  .v-top-side-new-library .v-top-side-new-library-header .v-top-side-new-title br {
    display: none;
  }
  .v-top-side-new-library .v-top-side-new-library-header .v-top-side-new-title span {
    display: inline-block;
    font-size: 36px;
  }
}
.v-top-side-new-library .v-top-side-sub-title {
  margin-bottom: 30px;
}
.v-top-side-new-library .v-top-side-sub-title .v-top-side-sub-title-text,
.v-top-side-new-library .v-top-side-sub-title .v-top-side-sub-title-btn {
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
}
@media (max-width: 1026px) {
  .v-top-side-new-library .v-top-side-sub-title .v-top-side-sub-title-text,
.v-top-side-new-library .v-top-side-sub-title .v-top-side-sub-title-btn {
    margin-top: 10px;
  }
}
.v-top-side-new-library .v-top-side-sub-title .v-top-side-sub-title-text {
  margin-right: 20px;
  font-weight: 300;
  font-size: 36px;
  color: #fff;
}
.v-top-side-new-library .v-top-side-sub-title .v-top-side-sub-title-text span {
  display: inline-block;
  border-bottom: 1px solid #ff7800;
  padding: 2px 4px;
  background-image: -webkit-gradient(linear, left top, right top, from(#00b7a8), to(#00a9d5));
  background-image: linear-gradient(to right, #00b7a8 0%, #00a9d5 100%);
  background-color: #00b7a8;
}
.v-top-side-new-library .v-top-side-sub-title .v-top-side-sub-title-btn {
  border-radius: 4px;
  padding: 11px 22px;
  font-size: 20px;
  text-align: center;
  color: #000;
  background-image: -webkit-gradient(linear, left bottom, left top, from(#ffe200), to(#ffe200));
  background-image: linear-gradient(to top, #ffe200 0%, #ffe200 100%);
  background-color: #ffe200;
}
.v-top-side-new-library .v-top-side-sub-title .v-top-side-sub-title-btn:hover {
  background-image: -webkit-gradient(linear, left top, right top, from(#fc0), to(#fffcb3));
  background-image: linear-gradient(to right, #fc0 0%, #fffcb3 100%);
}
@media (max-width: 1026px) {
  .v-top-side-new-library .v-top-side-sub-title {
    text-align: center;
  }
}
@media (max-width: 768px) {
  .v-top-side-new-library .v-top-side-sub-title {
    margin-bottom: 0;
  }
}
.v-top-side-new-library .v-lib-sert-list-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.v-top-side-new-library .v-lib-sert-list-wrap .v-lib-sert-top-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 11.7%;
  font-weight: 300;
  color: #fff;
}
.v-top-side-new-library .v-lib-sert-list-wrap .v-lib-sert-top-block .img {
  margin: auto 0 2px;
}
.v-top-side-new-library .v-lib-sert-list-wrap .v-lib-sert-top-block .v-lib-sert-top-block-title {
  margin-bottom: 4px;
  font-size: 16px;
}
.v-top-side-new-library .v-lib-sert-list-wrap .v-lib-sert-top-block .v-lib-sert-top-block-desc {
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 1.25;
  color: #d3e8e7;
}
.v-top-side-new-library .v-lib-sert-list-wrap .v-lib-sert-top-block .v-lib-sert-top-block-mat {
  font-size: 12px;
}
.v-top-side-new-library .v-lib-sert-list-wrap .v-lib-sert-top-block .v-lib-sert-top-block-mat i {
  display: inline-block;
  vertical-align: middle;
  margin-right: 3px;
  border-radius: 50px;
  padding: 2px 6px;
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
  line-height: 1;
  text-align: center;
}
.v-top-side-new-library .v-lib-sert-list-wrap .v-lib-sert-top-block .dg-cert-text {
  display: none;
}
.v-top-side-new-library .v-lib-sert-list-wrap .v-lib-sert-top-block:nth-child(1) i, .v-top-side-new-library .v-lib-sert-list-wrap .v-lib-sert-top-block:nth-child(1) .dg-cert-text a {
  background-image: linear-gradient(45deg, #ff4d20 0%, #f33af5 100%);
}
.v-top-side-new-library .v-lib-sert-list-wrap .v-lib-sert-top-block:nth-child(2) i, .v-top-side-new-library .v-lib-sert-list-wrap .v-lib-sert-top-block:nth-child(2) .dg-cert-text a {
  background-image: linear-gradient(45deg, #8758ff 0%, #a77cff 100%);
}
.v-top-side-new-library .v-lib-sert-list-wrap .v-lib-sert-top-block:nth-child(3) i, .v-top-side-new-library .v-lib-sert-list-wrap .v-lib-sert-top-block:nth-child(3) .dg-cert-text a {
  background-image: linear-gradient(45deg, #485eff 0%, #7791ff 100%);
}
.v-top-side-new-library .v-lib-sert-list-wrap .v-lib-sert-top-block:nth-child(4) i, .v-top-side-new-library .v-lib-sert-list-wrap .v-lib-sert-top-block:nth-child(4) .dg-cert-text a {
  background-image: linear-gradient(45deg, #0a87f8 0%, #37b9f5 100%);
}
.v-top-side-new-library .v-lib-sert-list-wrap .v-lib-sert-top-block:nth-child(5) i, .v-top-side-new-library .v-lib-sert-list-wrap .v-lib-sert-top-block:nth-child(5) .dg-cert-text a {
  background-image: linear-gradient(45deg, #00985d 0%, #00c574 100%);
}
.v-top-side-new-library .v-lib-sert-list-wrap .v-lib-sert-top-block:nth-child(6) i, .v-top-side-new-library .v-lib-sert-list-wrap .v-lib-sert-top-block:nth-child(6) .dg-cert-text a {
  background-image: linear-gradient(45deg, #58a900 0%, #25e100 100%);
}
.v-top-side-new-library .v-lib-sert-list-wrap .v-lib-sert-top-block:nth-child(7) i, .v-top-side-new-library .v-lib-sert-list-wrap .v-lib-sert-top-block:nth-child(7) .dg-cert-text a {
  background-image: linear-gradient(45deg, #ff8a00 0%, #ffc000 100%);
}
.v-top-side-new-library .v-lib-sert-list-wrap .v-lib-sert-top-block:nth-child(8) i, .v-top-side-new-library .v-lib-sert-list-wrap .v-lib-sert-top-block:nth-child(8) .dg-cert-text a {
  background-image: linear-gradient(45deg, #f00 0%, #fc8e00 100%);
}
.v-top-side-new-library .v-lib-sert-list-wrap .v-lib-sert-top-block.dg-done .img {
  position: relative;
}
.v-top-side-new-library .v-lib-sert-list-wrap .v-lib-sert-top-block.dg-done .img::before {
  content: "";
  position: absolute;
  left: -6px;
  top: -6px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-color: #52d65c;
}
.v-top-side-new-library .v-lib-sert-list-wrap .v-lib-sert-top-block.dg-done .img::after {
  content: "✔";
  position: absolute;
  left: -6px;
  top: -6px;
  width: 30px;
  height: 30px;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
}
.v-top-side-new-library .v-lib-sert-list-wrap .v-lib-sert-top-block.dg-done .dg-hider {
  display: none;
}
.v-top-side-new-library .v-lib-sert-list-wrap .v-lib-sert-top-block.dg-done .dg-cert-text {
  display: block;
  font-size: 12px;
  letter-spacing: -0.2px;
  color: #d3e8e7;
}
.v-top-side-new-library .v-lib-sert-list-wrap .v-lib-sert-top-block.dg-done .dg-cert-text a {
  display: inline-block;
  margin-top: 4px;
  border-bottom: 1px solid #ff7800;
  border-radius: 0;
  padding: 2px;
  font-size: 14px;
  color: #fff;
}
@media (min-width: 621px) and (max-width: 1210px) {
  .v-top-side-new-library .v-lib-sert-list-wrap {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .v-top-side-new-library .v-lib-sert-list-wrap .v-lib-sert-top-block {
    margin-bottom: 20px;
    width: 24%;
  }
}
@media (max-width: 620px) {
  .v-top-side-new-library .v-lib-sert-list-wrap {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .v-top-side-new-library .v-lib-sert-list-wrap .v-lib-sert-top-block {
    margin-bottom: 20px;
    width: 45%;
  }
}
.v-lib-free-sert {
  border-radius: 4px;
  padding: 23px 21px;
  background-color: #27668b;
}
.v-lib-free-sert .v-lib-free-sert-title {
  font-weight: 300;
  font-size: 24px;
  color: #fff;
}
.v-lib-free-sert .v-lib-free-sert-title span {
  border-bottom: 1px solid #ff9000;
  padding: 0 2px;
  color: #000;
  background-image: -webkit-gradient(linear, left top, right top, from(#fc0), to(#fff600));
  background-image: linear-gradient(to right, #fc0 0%, #fff600 100%);
  background-color: #fc0;
}
@media (max-width: 1026px) {
  .v-lib-free-sert .v-lib-free-sert-title {
    font-size: 18px;
  }
}
.v-lib-free-sert hr {
  display: block;
  margin: 18px 0 20px;
  outline: none;
  border: none;
  width: 100%;
  height: 1px;
  background-color: #628ba3;
}
.v-lib-free-sert .v-lib-free-sert-desc {
  margin-bottom: 10px;
  padding-left: 10px;
  font-weight: 300;
  font-size: 12px;
  line-height: 1.3;
  color: #fff;
}
.v-lib-free-sert .v-lib-free-sert-desc span {
  display: inline-block;
  margin: 2px 0;
  border-radius: 12px;
  padding: 2px 6px;
  font-weight: 700;
  font-size: 16px;
  background-image: linear-gradient(45deg, #ff4d20 0%, #f33af5 100%);
}
.v-lib-free-sert .v-lib-free-sert-desc:last-child {
  margin-bottom: 0;
}
.v-lib-free-sert .dg-cert-text {
  display: none;
}
.v-lib-free-sert.dg-done .img {
  position: relative;
}
.v-lib-free-sert.dg-done .img::before {
  content: "";
  position: absolute;
  left: -6px;
  top: -6px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background-color: #52d65c;
}
.v-lib-free-sert.dg-done .img::after {
  content: "✔";
  position: absolute;
  left: -6px;
  top: -6px;
  width: 30px;
  height: 30px;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #fff;
}
.v-lib-free-sert.dg-done .dg-hider {
  display: none;
}
.v-lib-free-sert.dg-done .dg-cert-text {
  display: block;
  margin-bottom: 10px;
  padding-left: 10px;
  font-size: 12px;
  letter-spacing: -0.2px;
  color: #d3e8e7;
}
.v-lib-free-sert.dg-done .dg-cert-text a {
  display: inline-block;
  margin-top: 4px;
  border-bottom: 1px solid #ff7800;
  border-radius: 0;
  padding: 2px;
  font-size: 14px;
  color: #fff;
  background-image: linear-gradient(45deg, #ff4d20 0%, #f33af5 100%);
}
@media (max-width: 1026px) {
  .v-lib-free-sert {
    padding: 10px;
  }
}
@media (max-width: 1026px) {
  .v-head-l-last .col-3 {
    display: none;
  }
  .v-head-l-last .col-9 {
    width: 100%;
  }
}
.v-new-branding-bg span.empty {
  padding: 20px;
}
.v-new-branding-bg span.empty .v-library-filter-search {
  margin-bottom: 10px;
}
.v-new-branding-bg span.empty .v-library-main-title {
  margin-bottom: 16px;
  font-weight: 300;
  font-size: 16px;
  color: #232323;
}
.dg-library__rate {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.dg-library__rate--pop-text {
  margin-bottom: 20px;
}
.dg-library__rate--pop-text .dg-title {
  font-weight: 700;
}
.dg-library__rate--pop-text li {
  margin-bottom: 6px;
}
.dg-library__rate--pop-text li:last-child {
  margin-bottom: 0;
}
.dg-library__rate--pop-text li:nth-child(n+2) {
  position: relative;
  padding-left: 20px;
}
.dg-library__rate--pop-text li:nth-child(n+2)::before {
  content: "";
  position: absolute;
  left: 6px;
  top: 8px;
  border-radius: 50%;
  width: 4px;
  height: 4px;
  background-color: #000;
}
.dg-library__rate--stars {
  margin-bottom: 20px;
  width: 30%;
  -webkit-filter: drop-shadow(0 3px 1px rgba(0, 0, 0, 0.3));
          filter: drop-shadow(0 3px 1px rgba(0, 0, 0, 0.3));
}
.dg-library__rate--number {
  font-weight: 500;
  color: #3a2;
}
.dg-library__rate--inpopup {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 10px;
}
.dg-library__rate--choose {
  height: auto;
  max-width: 102px;
  -webkit-transform: rotateY(-180deg);
          transform: rotateY(-180deg);
}
.dg-library__rate--choose .st0 {
  fill: #666;
  stroke: #666;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.dg-library__rate--choose .one:hover .st0 {
  fill: #ffcf00;
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
}
.dg-library__rate--choose .one:hover ~ .five .st0, .dg-library__rate--choose .one:hover ~ .four .st0, .dg-library__rate--choose .one:hover ~ .tree .st0, .dg-library__rate--choose .one:hover ~ .two .st0 {
  fill: #ffcf00;
}
.dg-library__rate--choose .two:hover .st0 {
  fill: #ffcf00;
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
}
.dg-library__rate--choose .two:hover ~ .five .st0, .dg-library__rate--choose .two:hover ~ .four .st0, .dg-library__rate--choose .two:hover ~ .tree .st0 {
  fill: #ffcf00;
}
.dg-library__rate--choose .tree:hover .st0 {
  fill: #ffcf00;
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
}
.dg-library__rate--choose .tree:hover ~ .five .st0, .dg-library__rate--choose .tree:hover ~ .four .st0 {
  fill: #ffcf00;
}
.dg-library__rate--choose .four:hover .st0 {
  fill: #ffcf00;
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
}
.dg-library__rate--choose .four:hover ~ .five .st0 {
  fill: #ffcf00;
}
.dg-library__rate--choose .five:hover .st0 {
  fill: #ffcf00;
  -webkit-transform: translateY(-1px);
          transform: translateY(-1px);
}
.dg-library__rate--wrapper {
  position: relative;
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  margin-bottom: 2px;
}
.dg-library__rate--wrapper:last-child {
  margin-bottom: 0;
}
.dg-library__rate--title {
  margin-right: 10px;
}
.dg-padding-top-20 {
  padding-top: 20px;
}
.dg-doc-review__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 30px;
}
.dg-doc-review__item .dg-title {
  margin-bottom: 4px;
  border-bottom: 1px dashed #eee;
  padding-bottom: 4px;
  font-size: 16px;
}
.dg-doc-review__item:last-child {
  margin-bottom: 0;
}
@media (max-width: 990px) {
  .dg-doc-review__item--text {
    margin-bottom: 10px;
  }
}
.dg-doc-review__item--old-cost {
  display: inline-block;
  margin-right: 4px;
  text-decoration: line-through;
  color: #999;
}
.dg-doc-review__item--new-cost {
  color: #f00;
}
@media (max-width: 990px) {
  .dg-doc-review__item {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.set-doc-review {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  margin-left: 20px;
}
@media (max-width: 990px) {
  .set-doc-review {
    margin-left: 0;
  }
}
.st_banner-library {
  margin: 0 0 20px;
  padding: 40px 0;
  color: #fff;
  background: #1a4f6e url("../img/library/bottom-heade.png");
}
.st_banner-library__top {
  padding: 38px 0;
  color: #fff;
  background: url("../img/library/top-head.png"), -webkit-gradient(linear, left top, right top, from(#009387), to(#008cb1));
  background: url("../img/library/top-head.png"), linear-gradient(to right, #009387 0%, #008cb1 100%);
}
.st_banner-library__top--title {
  font-weight: 700;
  font-size: 56px;
  line-height: 0.9;
  color: #fff;
}
@media (max-width: 990px) {
  .st_banner-library__top--title {
    font-size: 40px;
  }
}
.st_banner-library__top--descr {
  font-weight: 300;
  font-size: 40px;
}
.st_banner-library__top--ls {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  border-radius: 4px;
  padding: 20px;
  background-image: linear-gradient(45deg, #00b7a8 0%, #00a9d5 100%);
}
.st_banner-library__top--count span {
  position: relative;
  display: inline-block;
  margin-right: 4px;
  border-radius: 4px;
  padding: 4px 6px 5px;
  font-weight: 300;
  font-size: 40px;
  line-height: 1;
  text-shadow: 0 2px 0 rgba(186, 104, 0, 0.5);
  background-image: linear-gradient(45deg, #ff7800 0%, #ffc000 100%);
}
.st_banner-library__top--count span:last-child {
  margin-right: 10px;
}
@media (max-width: 480px) {
  .st_banner-library__top--count span {
    padding: 2px;
  }
}
.st_banner-library__top--count .v-top-side-new-counter-dot {
  margin-right: 13px;
}
.st_banner-library__top--count .v-top-side-new-counter-dot::before {
  content: "";
  position: absolute;
  right: -8px;
  bottom: 9px;
  display: block;
  border-radius: 50%;
  width: 4px;
  height: 4px;
  background-image: linear-gradient(45deg, #ff9c00 0%, #ffd200 100%);
  background-color: #fffcc5;
}
.st_banner-library__top--text {
  font-weight: 300;
  font-size: 18px;
}
.st_banner-library__top--text span {
  border-bottom: 1px solid #dccd25;
  padding: 0 2px;
  white-space: nowrap;
  background-image: -webkit-gradient(linear, left top, right top, from(#00bd16), to(#70d615));
  background-image: linear-gradient(to right, #00bd16 0%, #70d615 100%);
}
.st_banner-library__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-bottom: 30px;
  font-weight: 300;
  font-size: 36px;
  line-height: 1;
}
.st_banner-library__header span {
  display: inline-block;
  margin-right: auto;
  margin-left: 6px;
  border-bottom: 1px solid #ff7800;
  padding: 2px 4px;
  background-image: -webkit-gradient(linear, left top, right top, from(#00b7a8), to(#00a9d5));
  background-image: linear-gradient(to right, #00b7a8 0%, #00a9d5 100%);
}
@media (max-width: 990px) {
  .st_banner-library__header span {
    margin: 0 0 10px;
  }
}
@media (max-width: 990px) {
  .st_banner-library__header {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    text-align: center;
  }
}
.st_banner-library__btn {
  border-radius: 4px;
  padding: 11px 22px;
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  color: #000;
  background-image: -webkit-gradient(linear, left bottom, left top, from(#ffe200), to(#ffe200));
  background-image: linear-gradient(to top, #ffe200 0%, #ffe200 100%);
}
.st_banner-library__btn:hover {
  color: #000;
  background-image: -webkit-gradient(linear, left top, right top, from(#fc0), to(#fffcb3));
  background-image: linear-gradient(to right, #fc0 0%, #fffcb3 100%);
}
.st_banner-library__ls {
  border-radius: 4px;
  padding: 22px 20px;
  background-color: #27668b;
}
.st_banner-library__ls--title {
  margin-bottom: 30px;
  border-bottom: 1px solid #628ba3;
  padding-bottom: 20px;
  font-weight: 300;
  font-size: 24px;
}
.st_banner-library__ls--title span {
  border-bottom: 1px solid #ff9000;
  padding: 0 2px;
  color: #000;
  background-image: -webkit-gradient(linear, left top, right top, from(#fc0), to(#fff600));
  background-image: linear-gradient(to right, #fc0 0%, #fff600 100%);
}
.st_banner-library__ls--img {
  width: 49%;
}
.st_banner-library__ls--img img {
  vertical-align: bottom;
}
.st_banner-library__ls--text {
  padding-left: 14px;
  width: 51%;
  font-weight: 300;
  font-size: 12px;
  line-height: 1.3;
}
.st_banner-library__ls--text .st_title {
  margin-bottom: 10px;
}
.st_banner-library__ls--text .st_descr {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.st_banner-library__ls--text .st_descr span {
  margin: 2px auto 2px 0;
  border-radius: 12px;
  padding: 2px 6px;
  font-weight: 700;
  font-size: 16px;
  background-image: linear-gradient(45deg, #ff4d20 0%, #f33af5 100%);
}
@media (max-width: 990px) {
  .st_banner-library__ls {
    display: none;
  }
}
.st_banner-library__rs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.st_banner-library__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 13%;
  font-size: 12px;
}
.st_banner-library__list--title {
  margin-bottom: 4px;
  font-size: 16px;
}
.st_banner-library__list--descr {
  margin-bottom: 8px;
  line-height: 1.25;
  color: #d3e8e7;
}
.st_banner-library__list--descr .counter {
  display: inline-block;
  border-radius: 50px;
  padding: 2px 6px;
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  color: #fff;
}
.st_banner-library__list--descr .counter.count-1 {
  background-image: linear-gradient(45deg, #ff4d20 0%, #f33af5 100%);
}
.st_banner-library__list--descr .counter.count-2 {
  background-image: linear-gradient(45deg, #8758ff 0%, #a77cff 100%);
}
.st_banner-library__list--descr .counter.count-3 {
  background-image: linear-gradient(45deg, #485eff 0%, #7791ff 100%);
}
.st_banner-library__list--descr .counter.count-4 {
  background-image: linear-gradient(45deg, #0a87f8 0%, #37b9f5 100%);
}
.st_banner-library__list--descr .counter.count-5 {
  background-image: linear-gradient(45deg, #00985d 0%, #00c574 100%);
}
.st_banner-library__list--descr .counter.count-6 {
  background-image: linear-gradient(45deg, #58a900 0%, #25e100 100%);
}
.st_banner-library__list--descr .counter.count-7 {
  background-image: linear-gradient(45deg, #ff8a00 0%, #ffc000 100%);
}
.st_banner-library__list--img {
  margin-top: auto;
  margin-bottom: 4px;
}
.st_banner-library__list--img img {
  vertical-align: bottom;
}
@media (min-width: 621px) and (max-width: 1210px) {
  .st_banner-library__list {
    margin-bottom: 20px;
    width: 24%;
  }
}
@media (max-width: 620px) {
  .st_banner-library__list {
    margin-bottom: 20px;
    width: 45%;
  }
}
.st_right-sidebar__title {
  margin-bottom: 14px;
  font-weight: 700;
}
.st_right-sidebar__img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 14px;
}
.st_right-sidebar__img img {
  vertical-align: bottom;
  margin: 0 auto;
}
.st_right-sidebar__block {
  border-radius: 4px;
  padding: 6px;
  background: #fff;
}
.st_right-sidebar .st-button {
  width: 100%;
}
.st_right-sidebar--link {
  display: block;
  margin-bottom: 14px;
  color: #000;
}
.st_right-sidebar--link:hover {
  color: #000;
}
.dg-doc-search .items {
  padding-top: 20px;
}
.dg-doc-search .items span.empty {
  padding: 0;
}
.dg-doc-search .v-library__item {
  border-top: 1px solid #dedede;
  border-bottom: none;
  padding: 22px 0;
}
.dg-doc-search .v-library__item:last-child {
  padding-bottom: 0;
}
.st-flip_effect {
  position: relative;
  z-index: 99;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}
.st-flip_effect .st-front {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.st-flip_effect .st-back {
  position: absolute;
  top: 0;
  border: 1px solid #c7f1a6;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  background-color: white;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.st-flip_effect .st-back ul {
  overflow-y: scroll;
  height: 100%;
}
.st-flip_effect .st-back ul li {
  position: relative;
  margin-bottom: 4px;
  border-bottom: 1px solid #eee;
  padding: 0 10px 4px;
  color: #000;
}
.st-flip_effect .st-back ul li a {
  display: block;
  text-align: right;
}
.st-flip_effect .st-back ul li:first-child {
  padding: 6px 10px 4px;
}
.st-flip_effect .st-back ul li:last-child {
  margin-bottom: 0;
  border-bottom: none;
  padding: 0 10px 6px;
}
.st-flip_effect:hover .st-front {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}
.st-flip_effect:hover .st-back {
  -webkit-transform: rotateY(0deg);
          transform: rotateY(0deg);
}
.dg-mat-rate {
  padding: 4px 10px;
  background-image: -webkit-gradient(linear, left top, right top, from(#feffce), to(#fff));
  background-image: linear-gradient(to right, #feffce, #fff);
}
.dg-mat-rate__title {
  color: #888;
}
.dg-mat-rate__value {
  display: inline-block;
  margin: 0 4px;
  font-weight: 700;
}
.dg-mat-rate__gray {
  font-weight: 300;
  color: #888;
}
.dg-mat-rate__wrapper {
  padding-top: 10px;
}
@media (max-width: 990px) {
  .dg-mat-rate__wrapper {
    margin-bottom: 10px;
    padding-top: 0;
  }
}
.dg-search__btn {
  display: inline-block;
  border-radius: 4px;
  padding: 2px 30px 4px;
  font-weight: 300;
  font-size: 22px;
  color: #fff;
  background-color: #00c8f2;
  -webkit-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
}
.dg-search__btn:hover {
  color: #fff;
  background-color: #1ee6ff;
}
.dg-search__btn--wrapper {
  padding: 10px 0 0;
  text-align: right;
}
.dg-course-banner-direction {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.dg-course-search-teaser {
  margin-bottom: 20px;
}
.dg-course-search-teaser__ts {
  border-radius: 4px 4px 0 0;
}
.dg-course-search-teaser__ts--wrapper {
  border-radius: 4px 4px 0 0;
  padding: 10px 30px;
  background-color: #fff;
}
.dg-course-search-teaser__ts--wrapper .name {
  word-break: break-word;
}
.dg-course-search-teaser__ts--wrapper p {
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  color: #f00;
}
.dg-course-search-teaser__ts--wrapper .sale {
  display: block;
}
.dg-course-search-teaser__ts--wrapper .sale:last-child {
  margin-bottom: 0;
}
.dg-course-search-teaser__bs .v-new-site-courses-search-wrap {
  border-radius: 4px;
  padding: 10px;
  background: -webkit-gradient(linear, left top, right top, from(#007980), to(#008cb1));
  background: linear-gradient(to right, #007980 0%, #008cb1 100%);
}
.dg-course-search-teaser__bs .v-new-site-courses-search-wrap .v-contact-us-main-right-title {
  margin-bottom: 10px;
  font-weight: 300;
  font-size: 24px;
  color: #fff;
}
.dg-course-search-teaser__bs .v-new-site-courses-search-wrap .v-new-site-courses-bottom-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
}
.dg-course-search-teaser__bs .v-new-site-courses-search-wrap .v-new-site-courses-bottom-block b.dg-icon {
  display: inline-block;
  margin-right: 6px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  padding: 4px;
  width: 41px;
  height: 41px;
  min-width: 41px;
  background-color: rgba(245, 245, 245, 0.3);
}
.dg-course-search-teaser__bs .v-new-site-courses-search-wrap .v-new-site-courses-bottom-block b.dg-icon svg {
  vertical-align: bottom;
  width: 100%;
  height: auto;
}
.dg-course-search-teaser__bs .v-new-site-courses-search-wrap .v-new-site-courses-bottom-block i {
  display: inline-block;
  margin-right: 6px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  width: 41px;
  height: 41px;
  min-width: 41px;
  background: rgba(245, 245, 245, 0.3) url("../img/new_design/sprite.svg") no-repeat;
}
.dg-course-search-teaser__bs .v-new-site-courses-search-wrap .v-new-site-courses-bottom-block i.v-new-site-courses-bottom-block-icon1 {
  background-position: -58px 4px;
}
.dg-course-search-teaser__bs .v-new-site-courses-search-wrap .v-new-site-courses-bottom-block i.v-new-site-courses-bottom-block-icon2 {
  background-position: -58px -32px;
}
.dg-course-search-teaser__bs .v-new-site-courses-search-wrap .v-new-site-courses-bottom-block i.v-new-site-courses-bottom-block-icon3 {
  background-position: -58px -64px;
}
.dg-course-search-teaser__bs .v-new-site-courses-search-wrap .v-new-site-courses-bottom-block a.v-new-site-courses-bottom-block-text {
  display: inline-block;
  vertical-align: middle;
  max-width: 80%;
  font-weight: 300;
  font-size: 16px;
  color: #fff000;
}
.dg-course-search-teaser__bs .v-new-site-courses-search-wrap .v-new-site-courses-bottom-block a.v-new-site-courses-bottom-block-text span {
  border: 1px solid rgba(255, 222, 0, 0.5);
  border-radius: 4px;
  padding: 0 4px;
  font-weight: 600;
  font-size: 12px;
  color: #fff;
}
.dg-course-search-teaser__bs .v-new-site-courses-search-wrap .v-new-site-courses-bottom-block a.v-new-site-courses-bottom-block-text span {
  display: inline-block;
}
.dg-course-search-teaser__bs .v-new-site-courses-search-wrap .v-new-site-courses-bottom-block a.v-new-site-courses-bottom-block-text span.dg-price {
  border: none;
  padding: 0;
  font-weight: 400;
  font-size: 14px;
  color: #fff;
}
.dg-course-search-teaser__bs .v-new-site-courses-search-wrap .v-new-site-courses-search-block {
  position: relative;
  border-radius: 4px;
  background-color: transparent;
}
.dg-course-search-teaser__bs .v-new-site-courses-search-wrap .v-new-site-courses-search-block input {
  -webkit-appearance: textfield;
     -moz-appearance: textfield;
          appearance: textfield;
  outline: none;
  border: none;
  border-radius: 4px;
  padding: 0 0 2px 10px;
  width: 100%;
  min-height: 36px;
  font-weight: 300;
  font-size: 18px;
  color: #0b6f8d;
}
@media (max-width: 480px) {
  .dg-course-search-teaser__bs .v-new-site-courses-search-wrap .v-new-site-courses-search-block input {
    margin-bottom: 10px;
    width: 100%;
  }
}
.dg-course-search-teaser__bs .v-new-site-courses-search-wrap .v-new-site-courses-search-block button {
  position: absolute;
  right: 0;
  top: 0;
  margin: 3px 3px 4px 0;
  border-radius: 4px;
  padding: 4px 10px;
  font-weight: 700;
  font-size: 16px;
  color: #fff;
  background-color: #a4c9d6;
  -webkit-transition: 0.3s ease-out;
  transition: 0.3s ease-out;
}
@media (max-width: 480px) {
  .dg-course-search-teaser__bs .v-new-site-courses-search-wrap .v-new-site-courses-search-block button {
    position: relative;
    float: none;
    margin: 0;
    border-radius: 0;
    width: 100%;
  }
}
.dg-course-search-teaser__bs .v-new-site-courses-search-wrap .v-new-site-courses-search-block .v-search-result {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  z-index: 99;
  margin-top: 10px;
  border-radius: 3px;
  background-color: #fff;
}
.dg-course-search-teaser__bs .v-new-site-courses-search-wrap .v-new-site-courses-search-block .v-search-result .v-search-result__none {
  display: block;
  padding: 20px;
}
.dg-course-search-teaser__bs .v-new-site-courses-search-wrap .v-new-site-courses-search-block .v-search-result .v-search-result__none a {
  text-decoration: underline;
  color: #ff7800;
}
.dg-course-search-teaser__bs .v-new-site-courses-search-wrap .v-new-site-courses-search-block .v-search-result .v-search-result__none a:hover {
  text-decoration: none;
}
.dg-course-search-teaser__bs .v-new-site-courses-search-wrap .v-new-site-courses-search-block .v-search-result ul li a {
  display: block;
  width: 100%;
  font-size: 14px;
  text-decoration: none;
  color: #445d74;
}
.dg-course-search-teaser__bs .v-new-site-courses-search-wrap .v-new-site-courses-search-block .v-search-result ul li a:hover {
  background-color: rgba(191, 219, 234, 0.5);
}
.dg-course-search-teaser__bs .v-new-site-courses-search-wrap .v-new-site-courses-search-block .v-search-result ul li.v-search-result__header {
  border-bottom: 1px dashed #9ad0cb;
  font-weight: 600;
  font-size: 18px;
  color: #20746d;
}
.dg-course-search-teaser__bs .v-new-site-courses-search-wrap .v-new-site-courses-search-block .v-search-result ul li.v-search-result__footer {
  border-top: 1px dashed #9ad0cb;
}
.dg-course-search-teaser__bs .v-new-site-courses-search-wrap .v-new-site-courses-search-block .v-search-result ul li.v-search-result__item a .dg-cost {
  color: #ff2f2f;
}
.dg-course-search-teaser__bs .v-new-site-courses-search-wrap .v-new-site-courses-search-block .v-search-result ul:nth-child(2) {
  padding-top: 0;
}
.dg-course-search-teaser__bs .v-new-site-courses-search-wrap .v-new-site-courses-search-block {
  margin-top: 0;
}
@media (max-width: 768px) {
  .dg-course-search-teaser__bs .v-new-site-courses-search-wrap {
    position: relative;
  }
}
.dg-sb-course {
  margin-bottom: 20px;
  border-radius: 4px;
  padding: 10px;
  -webkit-box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.2);
          box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.2);
  background: #fff;
}
.dg-sb-course__img {
  display: block;
  overflow: hidden;
  margin: -10px -10px -26px;
  border-radius: 4px 4px 0 0;
  color: #000;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}
.dg-sb-course__img img {
  vertical-align: bottom;
}
.dg-sb-course__img:hover {
  color: #000;
  -webkit-filter: brightness(1.1);
          filter: brightness(1.1);
}
.dg-sb-course__header {
  position: relative;
  margin-bottom: 4px;
  font-size: 16px;
}
.dg-sb-course__info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 10px;
}
.dg-sb-course__category {
  display: inline-block;
  margin-bottom: 10px;
  border-radius: 4px;
  padding: 2px 6px;
  font-size: 12px;
  line-height: 1.2;
  background-color: #f5f5f5;
}
.dg-sb-course__category.dg-type {
  margin-bottom: 6px;
  background-color: rgba(250, 228, 2, 0.8);
}
.dg-sb-course__btn {
  display: block;
  border-radius: 4px;
  padding: 6px 10px;
  text-align: center;
  color: #fff;
  background-color: #3a2;
}
.ta-material__rs {
  float: right;
  margin: 0 0 0 20px;
  max-width: 130px;
}
.ta-material__rs img {
  vertical-align: bottom;
}
@media (max-width: 992px) {
  .ta-material__rs {
    margin: 0 0 10px 4px;
    max-width: 100px;
  }
}
.ta-material__wrapper {
  position: relative;
  border-bottom: 1px solid #dedede;
  padding: 30px 30px 20px;
  background: #fff;
}
.ta-material__wrapper .kr-show-all {
  display: inline-block;
  margin-bottom: 10px;
}
.ta-material__wrapper:last-child {
  margin-bottom: 20px;
  border-bottom: none;
  border-radius: 0 0 4px 4px;
}
@media (max-width: 992px) {
  .ta-material__wrapper {
    padding: 10px;
  }
}
.ta-material__btn {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.ta-material__btn a {
  color: #666;
}
@media (max-width: 1227px) {
  .ta-material__btn a.st-button:last-child {
    margin-bottom: 10px;
  }
}
.ta-material__btn-dop {
  margin-left: auto;
}
@media (max-width: 1227px) {
  .ta-material__btn-dop a {
    display: inline-block;
    margin-top: 10px;
  }
  .ta-material__btn-dop a:nth-child(n+2) {
    margin-left: 10px;
  }
}
@media (max-width: 1227px) {
  .ta-material__btn-dop {
    margin-left: 0;
  }
}
@media (max-width: 1227px) {
  .ta-material__btn {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
}
.ta-material__list {
  margin-bottom: 10px;
}
.ta-material__list--item {
  display: inline-block;
  margin-right: 10px;
}
.ta-material__list--item i {
  margin-right: 4px;
}
.ta-material__list--item:last-child {
  margin-right: 0;
  margin-bottom: 0;
}
.ta-material__list--item a, .ta-material__list--item span {
  color: #888;
}
@media (max-width: 480px) {
  .ta-material__list--item {
    margin-bottom: 6px;
    margin-left: 0;
  }
  .ta-material__list--item:first-child {
    display: block;
  }
}
@media screen and (min-width: 801px) {
  .ta-material__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.ta-material__tags {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.ta-material__tags a {
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  padding: 4px 12px;
  font-weight: 300;
  font-size: 12px;
  line-height: 1;
  color: #1987c4;
  background-color: #e0f1fd;
  -webkit-transition: 0.3s ease-out;
  transition: 0.3s ease-out;
}
.ta-material__tags a:hover {
  color: #e0f1fd;
  background-color: #1987c4;
}
@media (max-width: 991px) {
  .ta-material__tags {
    margin-bottom: 0;
  }
}
.ta-material__title {
  position: relative;
  display: block;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 18px;
  line-height: 1;
  color: #1987c4;
}
.ta-material__title::before {
  position: relative;
  top: -4px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 4px;
  padding: 0 4px;
  min-width: 42px;
  min-height: 22px;
  font-weight: 400;
  font-size: 12px;
  line-height: 1;
  text-transform: uppercase;
  color: #fff;
}
.ta-material--info-preuser {
  margin-bottom: 14px;
}
.ta-material--info-pre span {
  color: #797979;
}
.ta-material--info-pre:last-child {
  margin-bottom: 10px;
}
.st-library-filter__select .st-input {
  width: 100%;
}
.st-library-filter__select--title {
  display: block;
  margin: 0 0 8px;
  font-weight: 600;
  font-size: 16px;
  color: #284714;
}
.st-library-filter__title {
  display: block;
  margin-bottom: 10px;
  font-weight: 300;
  font-size: 20px;
}
.st-library-filter__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.st-library-filter__list li {
  margin: 0 6px 6px 0;
}
.st-library-filter__list li a {
  display: block;
  border-radius: 4px;
  padding: 4px 6px;
  line-height: 1;
  color: #e1f4ff;
  background-color: #6e99b2;
}
.st-library-filter__list li a:hover {
  color: #fff;
}
.st-library-filter__list li a.active {
  color: #fff;
  background-color: #72bb4c;
}
.st-library-filter__list li:last-child {
  margin-right: 0;
  margin-bottom: 0;
}
.st-library-filter__statistic {
  color: #76767a;
  line-height: 18px;
}
.st-library-filter__free-materials {
  margin-top: 18px;
}
.dg-marked {
  font-weight: 600;
}
.v-doc::before {
  content: "doc";
  background-color: #a1d9f9;
}
.v-docx::before {
  content: "docx";
  background-color: #caccff;
}
.v-pdf::before {
  content: "pdf";
  background-color: #ffbbb0;
}
.v-tpl::before {
  content: "tpl";
  background-color: #cfc7b7;
}
.v-txt::before {
  content: "txt";
  border: 1px solid #b4b4b4;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: #b4b4b4;
  background-color: transparent;
}
.v-pptx::before {
  content: "pptx";
  background-color: #ffcbae;
}
.v-ppsx::before {
  content: "ppsx";
  background-color: #ebc2b9;
}
.v-ppt::before {
  content: "ppt";
  background-color: #f9bbbb;
}
.v-pptm::before {
  content: "pptm";
  background-color: #f7bfb0;
}
.v-rar::before {
  content: "rar";
  background-color: #f4c0f1;
}
.v-7z::before {
  content: "7z";
  background-color: #d6d6d6;
}
.v-zip::before {
  content: "zip";
  background-color: #fbdc93;
}
.v-jpg::before {
  content: "jpg";
  background-color: #fadb4b;
}
.v-bmp::before {
  content: "bmp";
  background-color: #bcd5ff;
}
.v-png::before {
  content: "png";
  background-color: #abdde8;
}
.v-psd::before {
  content: "psd";
  background-color: #b7cee2;
}
.v-avi::before {
  content: "avi";
  background-color: #ffb3b3;
}
.v-flv::before {
  content: "flv";
  background-color: #e2c3c5;
}
.v-mp4::before {
  content: "mp4";
  background-color: #f1c5de;
}
.v-dif::before {
  content: "dif";
  background-color: #bdebb6;
}
.v-xls::before {
  content: "xls";
  background-color: #cbea81;
}
.v-xlsx::before {
  content: "xlsx";
  background-color: #a1edc1;
}
.dg-course-search__btn {
  position: relative;
  display: inline-block;
  border-radius: 4px;
  padding: 6px 20px;
  color: #fff;
  background-color: #72bb4c;
}
.dg-course-search__btn .dg-arrow {
  display: none;
}
.dg-course-search__btn:hover {
  color: #fff;
  background-color: #52d65c;
}
@media (max-width: 768px) {
  .dg-course-search__btn {
    width: 100%;
    text-align: center;
  }
  .dg-course-search__btn a {
    display: block;
  }
}
@-webkit-keyframes dgBtnAnimate {
  20% {
    -webkit-transform: none;
            transform: none;
  }
  22.5% {
    text-rendering: auto;
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  25% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  27.5% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  30% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  32.5% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  35% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  37.5% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  40% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
}
@keyframes dgBtnAnimate {
  20% {
    -webkit-transform: none;
            transform: none;
  }
  22.5% {
    text-rendering: auto;
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  25% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  27.5% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  30% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  32.5% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  35% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  37.5% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  40% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
}
.st-institute-libery {
  text-align: left;
}
.st-institute-libery__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
}
.st-institute-libery__header--link {
  display: inline-block;
  margin: 0 10px;
}
@media (max-width: 991px) {
  .st-institute-libery__header--link {
    margin: 0;
  }
}
.st-institute-libery__header--right-check {
  margin-left: auto;
}
.st-institute-libery__header--lable {
  margin-right: auto;
  font-weight: 600;
}
@media (max-width: 990px) {
  .st-institute-libery__header {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.dg-library-main-banner {
  display: block;
  margin-bottom: 20px;
}
.dg-library-main-banner__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 10px;
  background-color: #26252f;
}
.dg-library-main-banner__head svg {
  margin-right: 10px;
}
.dg-library-main-banner__head--text {
  font-size: 16px;
  text-align: center;
  color: #fff;
}
@media (max-width: 360px) {
  .dg-library-main-banner__head--text br {
    display: none;
  }
}
.dg-library-main-banner__body {
  padding: 10px;
  -webkit-box-shadow: inset 0 2px 50px -10px rgba(0, 0, 0, 0.8);
          box-shadow: inset 0 2px 50px -10px rgba(0, 0, 0, 0.8);
  font-weight: 700;
  font-size: 26px;
  line-height: 1.2;
  text-align: center;
  color: #fff;
  background-image: -webkit-gradient(linear, left top, right bottom, color-stop(40%, #53535b), color-stop(#414046), color-stop(#555459), color-stop(#4f494d), color-stop(#413c3d), to(#999));
  background-image: linear-gradient(to right bottom, #53535b 40%, #414046, #555459, #4f494d, #413c3d, #999);
}
.dg-library-main-banner__footer {
  border: 2px solid #333;
  padding: 10px;
  text-align: center;
  background-color: #fefaeb;
}
@media (min-width: 991px) {
  .dg-library-main-banner__footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}
.dg-library-main-banner__sale {
  border-radius: 4px;
  background-color: #f9e08b;
}
.dg-library-main-banner__sale--head {
  font-weight: 800;
  font-size: 18px;
  line-height: 1;
  color: #f25001;
}
.dg-library-main-banner__sale--date {
  font-weight: 600;
  font-size: 11px;
  color: #000;
}
.dg-library-main-banner__sale--value {
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 16px;
  color: #000;
}
@media (min-width: 991px) {
  .dg-library-main-banner__sale--value {
    margin: 0 10px;
  }
}
.dg-library-main-banner__sale--btn {
  border-radius: 4px;
  padding: 10px;
  color: #fff;
  background-color: #f25001;
}
@media (min-width: 991px) {
  .dg-library-main-banner__sale {
    clip-path: polygon(0 0, 0 100%, calc(100% - 10px) 100%, 100% 50%, calc(100% - 10px) 0);
    padding: 6px 20px 6px 6px;
  }
}
@media (max-width: 990px) {
  .dg-library-main-banner__sale {
    margin-bottom: 10px;
    padding: 10px;
  }
}
.dg-library-main-banner:hover .dg-library-main-banner__sale--btn {
  -webkit-animation: dgBtnAnimate 1.5s 1;
          animation: dgBtnAnimate 1.5s 1;
}
.kr-shared-one .dg-social-icons {
  margin: 0;
}
.dg-course-search {
  position: relative;
  padding: 20px;
  background: -webkit-gradient(linear, left top, right top, from(#007980), to(#008cb1));
  background: linear-gradient(to right, #007980, #008cb1);
}
.dg-course-search__row-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 4px 6px 6px 4px;
  padding: 2px;
  background-color: #fff;
}
.dg-course-search__input {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  margin-right: 4px;
  border: none;
  padding: 2px 2px 2px 10px;
  min-width: 30px;
  font-weight: 300;
  font-size: 18px;
  color: #0b6f8d;
}
.dg-course-search__input--btn {
  border-radius: 4px;
  padding: 6px 24px;
  font-weight: 700;
  font-size: 16px;
  color: #fff;
  background-color: #a4c9d6;
  -webkit-transition: 0.3s ease-out;
  transition: 0.3s ease-out;
}
.dg-course-search__input--btn:hover {
  background-color: #5581a0;
}
.dg-course-search__result:not(:last-child) {
  margin-bottom: 20px;
}
.dg-course-search__result a {
  display: block;
  padding: 2px 10px;
  font-size: 14px;
  text-decoration: none;
  color: #445d74;
}
.dg-course-search__result a:hover {
  background-color: #dfedf4;
}
.dg-course-search__result .dg-cost {
  color: #ff2f2f;
}
.dg-course-search__result--wrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  z-index: 99;
  border-radius: 0 0 4px 4px;
  padding: 20px 10px;
  -webkit-box-shadow: 0 10px 30px -10px rgba(0, 0, 0, 0.6);
          box-shadow: 0 10px 30px -10px rgba(0, 0, 0, 0.6);
  background-color: #fff;
}
.dg-course-search__result--wrapper.fix {
  overflow-y: auto;
  border-radius: 4px;
  padding: 4px 0 6px;
}
@media (min-width: 769px) {
  .dg-course-search__result--wrapper.fix {
    margin-top: -28px;
    max-height: 50vh;
  }
}
.dg-course-search__result--header {
  margin: 0 10px 10px;
  border-bottom: 1px dashed #9ad0cb;
  padding-bottom: 10px;
  font-weight: 600;
  font-size: 18px;
  color: #20746d;
}
.dg-course-search__result--footer {
  text-align: right;
}
.dg-course-search__result--none {
  padding: 20px;
}
.dg-course-search__result--none a {
  text-decoration: underline;
  color: #ff7800;
}
.dg-course-search__result--none a:hover {
  text-decoration: none;
}
.a-namb {
  display: block;
  margin-bottom: 10px;
  font-size: 12px;
  color: #b8b8b8;
}
.a-namb span {
  border-radius: 50%;
  padding: 3px 6px;
  width: 10px;
  height: 10px;
  font-style: normal;
  color: #fff;
  background: #ddd;
}
.a-txt {
  font-size: 12px;
}
.preview {
  overflow: auto;
  max-height: 480px;
}
@media (max-width: 722px) {
  .a-pad {
    max-height: calc(60vh - 80px);
  }
}
.a-dog {
  margin-bottom: 20px;
  background: #fff;
}
.a-dogs sup,
.a-dogs sub {
  vertical-align: super !important;
  font-size: smaller !important;
  line-height: 150% !important;
}
.a-dogs i {
  width: auto !important;
  height: auto !important;
  background: none !important;
}
.a-dogs p,
.a-dogs ul,
.a-dogs ol {
  font-size: inherit !important;
  line-height: inherit !important;
  color: inherit !important;
  background: none !important;
}
.st-library-popup {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999999;
  background-color: rgba(255, 255, 255, 0.95);
}
.st-library-popup__wrapp {
  position: absolute;
  top: 10%;
}
.st-library-popup__arrow {
  position: relative;
  clip-path: polygon(40% 0%, 40% 20%, 100% 20%, 100% 80%, 40% 80%, 40% 100%, 0% 50%);
  margin-bottom: 40px;
  width: 270px;
  height: 140px;
  background-image: -webkit-gradient(linear, left bottom, right top, from(#469501), to(#95e825));
  background-image: linear-gradient(to right top, #469501, #95e825);
  -webkit-animation: arrow 2.5s ease-in-out infinite;
          animation: arrow 2.5s ease-in-out infinite;
}
.st-library-popup__text {
  width: 270px;
  font-weight: 800;
  font-size: 24px;
  text-align: center;
  text-shadow: 0 2px 2px rgba(69, 158, 48, 0.1);
  -webkit-animation: btn-animation 2.5s ease-in-out infinite;
          animation: btn-animation 2.5s ease-in-out infinite;
}
.v-slider-new .v-slider-footer {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.rank {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}
.rank__rating {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.rank__title {
  margin-right: 10px;
}
.rank__number {
  color: #2bae0d;
}
.rank__apprise {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.rank__img {
  fill: #666;
  stroke: #666;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
/* ---- Logic of working stars hover behavior ---- */
.rank__stars {
  height: auto;
  max-width: 102px;
  -webkit-transform: rotateY(-180deg);
          transform: rotateY(-180deg);
}
.rank__stars .rank__star--one:hover .rank__img {
  fill: #ffcd00;
}
.rank__stars .rank__star--one:hover ~ .rank__star--five .rank__img, .rank__stars .rank__star--one:hover ~ .rank__star--four .rank__img, .rank__stars .rank__star--one:hover ~ .rank__star--tree .rank__img, .rank__stars .rank__star--one:hover ~ .rank__star--two .rank__img {
  fill: #ffcd00;
}
.rank__stars .rank__star--two:hover .rank__img {
  fill: #ffcd00;
}
.rank__stars .rank__star--two:hover ~ .rank__star--five .rank__img, .rank__stars .rank__star--two:hover ~ .rank__star--four .rank__img, .rank__stars .rank__star--two:hover ~ .rank__star--tree .rank__img {
  fill: #ffcd00;
}
.rank__stars .rank__star--tree:hover .rank__img {
  fill: #ffcd00;
}
.rank__stars .rank__star--tree:hover ~ .rank__star--five .rank__img, .rank__stars .rank__star--tree:hover ~ .rank__star--four .rank__img {
  fill: #ffcd00;
}
.rank__stars .rank__star--four:hover .rank__img {
  fill: #ffcd00;
}
.rank__stars .rank__star--four:hover ~ .rank__star--five .rank__img {
  fill: #ffcd00;
}
.rank__stars .rank__star--five:hover .rank__img {
  fill: #ffcd00;
}
@-webkit-keyframes arrow {
  50% {
    -webkit-transform: translateX(-90px);
            transform: translateX(-90px);
  }
}
@keyframes arrow {
  50% {
    -webkit-transform: translateX(-90px);
            transform: translateX(-90px);
  }
}
@-webkit-keyframes btn-animation {
  40% {
    text-shadow: 0 2px 5px rgba(69, 158, 48, 0.7);
  }
}
@keyframes btn-animation {
  40% {
    text-shadow: 0 2px 5px rgba(69, 158, 48, 0.7);
  }
}
.st-yandex__wrapper {
  margin: 0 -30px 20px;
  padding: 20px 30px;
  background-color: #f5f6f6;
}
.st-yandex__wrapper input[type=checkbox].st-input {
  margin-left: 6px;
}
.st-yandex__wrapper input[type=checkbox].st-input ~ label::before {
  border: 1px solid #000;
  width: 14px;
  height: 14px;
  background-color: #fff;
}
.st-yandex__wrapper input[type=checkbox].st-input ~ label::after {
  font-size: 14px;
  color: #000;
}
.st-yandex__wrapper label {
  font-size: 18px;
}
.st-yandex__wrapper .st-input__flex-revert:not(:last-child) {
  margin-bottom: 10px;
}
.st-yandex__title {
  margin-bottom: 10px;
  padding-top: 20px;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.2;
  text-align: center;
}
.st-yandex__btn {
  display: inline-block;
  padding: 10px 20px;
  text-align: center;
}
div.bonus-bill-popup {
  position: relative;
}
div.bonus-bill-popup::after {
  content: "";
  position: absolute;
  right: -23px;
  top: -15px;
  width: 25px;
  height: 25px;
  background-color: #fff;
}
div.bonus-bill-popup p.bonus-bill-popup__name {
  margin-bottom: 16px;
  font-size: 22px;
}
div.bonus-bill-popup p.bonus-bill-popup__name b {
  font-weight: 700;
}
div.bonus-bill-popup p.bonus-bill-popup__name span {
  font-weight: 700;
  color: #ff3d00;
}
div.bonus-bill-popup p.bonus-bill-popup__name--medium {
  font-size: 18px;
}
div.bonus-bill-popup p.bonus-bill-popup__text {
  margin-bottom: 16px;
  font-size: 16px;
}
div.bonus-bill-popup p.bonus-bill-popup__text b {
  font-weight: 600;
}
div.bonus-bill-popup p.bonus-bill-popup__text span {
  font-weight: 700;
  color: #ff3d00;
}
div.bonus-bill-popup p.bonus-bill-popup__text--big {
  font-size: 18px;
}
div.bonus-bill-popup p.bonus-bill-popup__excretion {
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 14px;
  color: #ff3d00;
}
div.bonus-bill-popup p.bonus-bill-popup__excretion span {
  text-decoration: line-through;
}
div.bonus-bill-popup a.bonus-bill-popup__btn {
  display: inline-block;
  margin-top: 4px;
  margin-bottom: 20px;
  border-radius: 4px;
  padding: 16px 25px 17px;
  -webkit-box-shadow: 0 0 30px rgba(255, 222, 0, 0.35), inset 0 -2px 2px rgba(0, 0, 0, 0.25);
          box-shadow: 0 0 30px rgba(255, 222, 0, 0.35), inset 0 -2px 2px rgba(0, 0, 0, 0.25);
  font-weight: 600;
  font-size: 14px;
  line-height: 123%;
  color: #000;
  background: #ffde00;
  -webkit-transition-property: -webkit-box-shadow;
  transition-property: -webkit-box-shadow;
  transition-property: box-shadow;
  transition-property: box-shadow, -webkit-box-shadow;
  -webkit-transition-duration: 0.5s;
          transition-duration: 0.5s;
}
div.bonus-bill-popup a.bonus-bill-popup__btn:hover {
  -webkit-box-shadow: 0 0 30px #edd00d, inset 0 -2px 2px rgba(0, 0, 0, 0.25);
          box-shadow: 0 0 30px #edd00d, inset 0 -2px 2px rgba(0, 0, 0, 0.25);
}
div.bonus-bill-popup a.bonus-bill-popup__btn--mb {
  margin-bottom: 0;
}
div.bonus-bill-popup p.bonus-bill-popup__close {
  margin-right: -10px;
  margin-bottom: -10px;
  text-align: right;
}
div.bonus-bill-popup p.bonus-bill-popup__close a {
  font-style: italic;
  font-size: 12px;
  line-height: 130%;
  color: #a7a7ab;
}
div.black-friday-popup {
  position: relative;
  border-radius: 4px;
  padding-top: 20px;
  text-align: center;
  background-color: #181818;
}
div.black-friday-popup .black-friday-popup__subtitle {
  margin: 15px 0 12px;
  padding: 0 15px;
  font-weight: 700;
  font-size: 24px;
  line-height: 115%;
  color: #fff;
}
div.black-friday-popup .black-friday-popup__subtitle span {
  color: #f60;
}
div.black-friday-popup .black-friday-popup__title {
  margin: 0 0 20px;
  padding: 0 15px;
  font-weight: 700;
  font-size: 32px;
  text-align: center;
  color: #fff;
}
div.black-friday-popup .black-friday-popup__btn {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 0 0 40px;
  border-radius: 4px;
  padding: 16px 0;
  width: 100%;
  max-width: 390px;
  -webkit-box-shadow: 0 0 30px rgba(255, 222, 0, 0.35), inset 0 -2px 2px rgba(0, 0, 0, 0.25);
          box-shadow: 0 0 30px rgba(255, 222, 0, 0.35), inset 0 -2px 2px rgba(0, 0, 0, 0.25);
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  color: #000;
  background: #ffde00;
  -webkit-transition-property: -webkit-box-shadow;
  transition-property: -webkit-box-shadow;
  transition-property: box-shadow;
  transition-property: box-shadow, -webkit-box-shadow;
  -webkit-transition-duration: 500ms;
          transition-duration: 500ms;
}
div.black-friday-popup .black-friday-popup__btn:hover {
  -webkit-box-shadow: 0 0 30px #edd00d, inset 0 -2px 2px rgba(0, 0, 0, 0.25);
          box-shadow: 0 0 30px #edd00d, inset 0 -2px 2px rgba(0, 0, 0, 0.25);
}
div.black-friday-popup .black-friday-popup__bottom {
  border-radius: 0 0 4px 4px;
  padding: 4px 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: #000;
  background: #f0f0f0;
}
div.black-friday-popup .black-friday-popup__discount {
  position: absolute;
  left: -45px;
  top: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 50%;
  width: 90px;
  height: 90px;
  font-weight: 600;
  font-size: 16px;
  line-height: 110%;
  text-align: center;
  color: #fff;
  background-color: #f60;
}
div.black-friday-popup .black-friday-popup__discount::after {
  content: "";
  position: absolute;
  left: 65%;
  top: 100%;
  width: 70px;
  height: 67px;
  background: url("../img//arrow-black-friday.svg") left top no-repeat;
}
div.black-friday-popup .black-friday-popup__discount div {
  max-width: 80px;
}
div.black-friday-popup .black-friday-popup__discount span {
  font-weight: 700;
}
div.black-friday-popup .black-friday-popup__close {
  position: absolute;
  right: 8px;
  top: 8px;
}
.st-library-filter__button-close {
  display: none;
  margin-top: 15px;
}
@media (max-width: 768px) {
  .st-library-filter__button-close {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
  }
}
@media (max-width: 722px) {
  div.black-friday-popup .black-friday-popup__discount {
    position: static;
    margin: 0 auto;
    width: 100%;
    height: auto;
    background-color: transparent;
  }
  div.black-friday-popup .black-friday-popup__discount::after {
    display: none;
  }
  div.black-friday-popup .black-friday-popup__discount div {
    max-width: 100%;
  }
  div.black-friday-popup .black-friday-popup__discount span {
    color: #f60;
  }
  div.black-friday-popup .black-friday-popup__subtitle {
    margin: 8px 0 12px;
  }
}
/*# sourceMappingURL=new-site-library.css.map */
