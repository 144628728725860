@import "helpers/variables";
@import "old-browsers";

$img-path: "../img";

@import "user-attention";

.v-body-library {
    & .header {
        margin: 0 0 20px;
        // для видеорекламы
        position: relative;
        z-index: 9001;
    }

    .a-testyng-block {
        position: relative;
    }

    & .st_banner-library__top {
        margin: -20px 0 0;
    }
}

.v-library_filter {
    margin-bottom: 20px;
    border-radius: 4px;
    padding: 30px;
    background-color: #fff;

    .v-library-main-title {
        margin-bottom: 16px;
        font-weight: 300;
        font-size: 34px;
        line-height: 1;
        color: #232323;

        span {
            font-size: 24px;
        }

        @media #{$max-w-md} {
            font-size: 22px;

            span {
                font-size: 16px;
            }
        }
    }

    .v-inline-list-wrap {
        border-radius: 4px;
        padding: 14px 14px 10px;
        background-color: #6e99b2;

        .v-inline-list {
            li {
                display: inline-block;
                margin-bottom: 4px;

                a {
                    display: block;
                    padding: 4px 6px;
                    line-height: 1;
                    color: #e1f4ff;

                    &.active {
                        border-radius: 4px;
                        color: #345263;
                        background-color: #fff;
                    }
                }
            }
        }
    }

    .v-select-block {
        @media #{$max-w-md} {
            margin-bottom: 10px;
        }
    }

    @media #{$max-w-md} {
        padding: 10px;
    }
}

.v-library-filter-search {
    position: relative;
    margin-bottom: 20px;
    border: 1px solid #3db9ff;
    border-bottom: 3px solid #3db9ff;
    border-radius: 4px;
    box-shadow: 0 10px 15px rgba(0, 135, 212, 0.26);
    background-color: #fff;

    &::before {
        content: "";
        position: absolute;
        left: 22px;
        top: -9px;
        display: block;
        width: 16px;
        height: 9px;
        background: url("#{$path-img}library/search-before.png") no-repeat;
    }

    input[type="search"] {
        border: none;
        padding-bottom: 2px;
        padding-left: 12px;
        width: 100%;
        min-height: 42px;
        font-weight: 300;
        font-size: 18px;
        line-height: 1;
        color: #5f89a5;
    }

    button {
        position: absolute;
        right: 0;
        top: 0;
        border-left: 1px solid #3db9ff;
        padding-top: 4px;
        width: 50px;
        height: 100%;
        background-color: transparent;

        span {
            display: inline-block;
            width: 20px;
            height: 20px;
            background: none, url("#{$path-img}new_design/sprite.svg") no-repeat;
            background-position: -106px -248px;
        }
    }
}

.v-library_item-info {
    border-bottom: 1px solid #dedede;
    border-radius: 4px 4px 0 0;
    padding: 24px 30px 28px;
    background-color: #fff;

    p {
        font-weight: 300;
        font-size: 26px;
        line-height: 1;

        @media #{$max-w-md} {
            font-size: 22px;
        }
    }

    @media #{$max-w-md} {
        padding: 10px;
    }
}

.v-library__item {
    position: relative;
    border-bottom: 1px solid #dedede;

    .wrapper {
        position: relative;
        padding: 30px;

        @media #{$max-w-md} {
            padding: 10px;
        }
    }

    .v-document-general-info {
        .v-document-general-info__body {
            .v-left-block {
                display: table-cell;
                vertical-align: top;
                width: 60%;

                & .dg-choose-btn {
                    display: inline-block;
                    margin-bottom: 20px;
                    border-radius: 4px;
                    padding: 10px 20px;
                    color: #fff;
                    background-color: #00c8f2;

                    &:hover {
                        color: #fff;
                        background-color: adjust-color(#00c8f2, $red: 30, $green: 30, $blue: 30);
                    }
                }

                .v-document-general-info__title {
                    position: relative;
                    margin-bottom: 10px;
                    font-weight: 300;
                    font-size: 22px;
                    line-height: 1;
                    color: #1987c4;

                    &::before {
                        content: "";
                        position: relative;
                        top: -2px;
                        display: inline-block;
                        vertical-align: middle;
                        border-radius: 4px;
                        padding: 0 4px;
                        min-width: 42px;
                        min-height: 20px;
                        box-sizing: border-box;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 21px;
                        text-align: center;
                        text-transform: uppercase;
                        color: #fff;
                        background-color: #ccc;
                    }

                    @media #{$max-w-xs} {
                        font-size: 18px;
                    }
                }

                @media #{$max-w-xs} {
                    display: block;
                    width: 100%;
                }
            }

            .v-right-block {
                display: table-cell;
                vertical-align: top;
                padding-left: 20px;
                width: 20%;

                .v-library__cover {
                    margin-top: 2px;

                    @media #{$max-w-xs} {
                        display: block;
                        margin: 20px auto 0;
                    }
                }

                @media #{$max-w-xs} {
                    display: block;
                    padding: 0;
                    width: 100%;
                }
            }
        }

        .v-inline-list {
            li {
                display: inline-block;

                @media #{$max-w-xs} {
                    margin: 0 4px 4px 0;
                    padding-top: 4px;
                }
            }

            &.icon-list {
                display: flex;
                justify-content: flex-end;
                margin-bottom: 10px;

                li {
                    margin-right: 20px;
                    color: #888;

                    i {
                        margin-right: 4px;
                    }

                    a {
                        color: #888;

                        &:hover {
                            text-decoration: underline;
                        }
                    }

                    &:first-child {
                        margin-right: auto;

                        @media #{$max-w-xs} {
                            width: 100%;
                        }
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }

                @media #{$max-w-xs} {
                    flex-wrap: wrap;
                    justify-content: flex-start;
                }
            }

            &.meta-tags {
                li {
                    a {
                        display: block;
                        border-radius: 4px;
                        padding: 5px 12px;
                        font-weight: 300;
                        font-size: 12px;
                        line-height: 1;
                        color: #1987c4;
                        background-color: #e0f1fd;
                        transition: 0.3s ease-out;

                        &:hover {
                            color: #e0f1fd;
                            background-color: #1987c4;
                        }

                        @media #{$max-w-xs} {
                            display: inline-block;
                        }
                    }
                }
            }
        }
    }

    &:last-child {
        border-bottom: none;
        border-radius: 0 0 4px 4px;

        .wrapper {
            border-radius: 0 0 4px 4px;
        }
    }

    &.v-document-format {
        .v-document-general-info {
            .v-document-general-info__body {
                .v-left-block {
                    .v-document-general-info__title {
                        &.v-doc {
                            &::before {
                                content: "doc";
                                background-color: #a1d9f9;
                            }
                        }

                        &.v-docx {
                            &::before {
                                content: "docx";
                                background-color: #caccff;
                            }
                        }

                        &.v-pdf {
                            &::before {
                                content: "pdf";
                                background-color: #ffbbb0;
                            }
                        }

                        &.v-tpl {
                            &::before {
                                content: "tpl";
                                background-color: #cfc7b7;
                            }
                        }

                        &.v-txt {
                            &::before {
                                content: "txt";
                                border: 1px solid #b4b4b4;
                                box-sizing: border-box;
                                color: #b4b4b4;
                                background-color: transparent;
                            }
                        }

                        &.v-pptx {
                            &::before {
                                content: "pptx";
                                background-color: #ffcbae;
                            }
                        }

                        &.v-ppsx {
                            &::before {
                                content: "ppsx";
                                background-color: #ebc2b9;
                            }
                        }

                        &.v-ppt {
                            &::before {
                                content: "ppt";
                                background-color: #f9bbbb;
                            }
                        }

                        &.v-pptm {
                            &::before {
                                content: "pptm";
                                background-color: #f7bfb0;
                            }
                        }

                        &.v-rar {
                            &::before {
                                content: "rar";
                                background-color: #f4c0f1;
                            }
                        }

                        &.v-7z {
                            &::before {
                                content: "7z";
                                background-color: #d6d6d6;
                            }
                        }

                        &.v-zip {
                            &::before {
                                content: "zip";
                                background-color: #fbdc93;
                            }
                        }

                        &.v-jpg {
                            &::before {
                                content: "jpg";
                                background-color: #fadb4b;
                            }
                        }

                        &.v-bmp {
                            &::before {
                                content: "bmp";
                                background-color: #bcd5ff;
                            }
                        }

                        &.v-png {
                            &::before {
                                content: "png";
                                background-color: #abdde8;
                            }
                        }

                        &.v-psd {
                            &::before {
                                content: "psd";
                                background-color: #b7cee2;
                            }
                        }

                        &.v-avi {
                            &::before {
                                content: "avi";
                                background-color: #ffb3b3;
                            }
                        }

                        &.v-flv {
                            &::before {
                                content: "flv";
                                background-color: #e2c3c5;
                            }
                        }

                        &.v-mp4 {
                            &::before {
                                content: "mp4";
                                background-color: #f1c5de;
                            }
                        }

                        &.v-dif {
                            &::before {
                                content: "dif";
                                background-color: #bdebb6;
                            }
                        }

                        &.v-xls {
                            &::before {
                                content: "xls";
                                background-color: #cbea81;
                            }
                        }

                        &.v-xlsx {
                            &::before {
                                content: "xlsx";
                                background-color: #a1edc1;
                            }
                        }
                    }
                }
            }
        }
    }
}

.v-library-docs-new {
    border-radius: 4px;

    .v-library-docs-new-block {
        border-bottom: 1px solid #dedede;

        a {
            .v-library-docs-new-block-cirlce-wrap {
                display: table-cell;
                vertical-align: middle;

                .v-library-docs-new-block-cirlce {
                    display: inline-block;
                    border-radius: 50%;
                    padding: 5px 0 0;
                    width: 70px;
                    height: 70px;
                    text-align: center;
                    background-color: #ececec;

                    img {
                        display: block;
                        margin: auto;
                    }
                }
            }

            span {
                color: #444;
            }
        }

        &:first-child {
            a {
                border-radius: 4px 4px 0 0;
            }
        }

        &:last-child {
            border-bottom: none;

            a {
                border-radius: 0 0 4px 4px;
            }
        }
    }
}

.v-body-library {
    .container-fluid {
        padding: 0; //временное решение
    }
}

.wrap-library {
    margin-bottom: 20px;
    border-radius: 4px;
    background-color: #fff;

    &__flex {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }

    &__right {
        margin: 25px 30px 0 15px;

        @media (max-width: 990px) {
            margin-top: 10px;
        }
    }
}

.wrap-library--relative {
    position: relative;
}

.v-present-item {
    border-top: 1px solid #dedede;
    padding: 20px 30px;

    .a-namb {
        font-weight: 300;
        font-size: 22px;
        color: #000;

        span {
            display: inline-block;
            border-radius: 50px;
            padding: 0 6px 2px;
            width: auto;
            height: auto;
            line-height: 24px;
            text-align: center;
            color: #000;
            background-color: #ffd200;
        }
    }

    .a-txt {
        font-size: 14px;
        word-wrap: break-word;
        color: #383838;
    }
}

.wrap-library-inside {
    overflow-wrap: break-word;
    padding: 22px 30px 30px;

    &.wrap-library-inside-similar {
        padding-bottom: 20px;
    }

    @media #{$max-w-md} {
        padding: 10px;
    }
}

.v-library-new-title {
    padding: 26px 30px 0;
    font-weight: 300;
    font-size: 26px;
    line-height: 1;

    &.pb {
        padding-bottom: 26px;
    }

    @media #{$max-w-md} {
        padding: 10px 10px 0;
    }

    @media #{$max-w-xs} {
        padding-top: 20px;
        font-size: 18px;
    }
}

.v-interesting-links {
    position: relative;
    display: block;
    margin-bottom: 14px;
    padding-left: 12px;
    font-weight: 300;
    font-size: 18px;
    line-height: 1.2;
    color: #0e75af;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 7px;
        display: block;
        border-radius: 50%;
        width: 6px;
        height: 6px;
        background-color: #0e75af;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.v-slider-new {
    margin-bottom: 20px;
    border-radius: 4px;
    box-shadow: 0 10px 40px -15px rgba(0, 0, 0, 0.5);

    .v-slider-header {
        border-radius: 4px 4px 0 0;
        padding: 20px;
        background-image: linear-gradient(to right, #55c709 0%, #86c71b 32.5%, #86c71b 50%, #86c71b 67.5%, #55c709 100%);
        background-color: #55c709;

        .kr-shared-block {
            float: right;

            .kr-shared-one {
                vertical-align: middle;

                .kr-shared {
                    vertical-align: middle;
                    width: 29px;
                    height: 29px;
                    background: none, url("#{$path-img}new_design/sprite.svg") no-repeat -207px -213px;
                    background-size: inherit;
                }

                .a-b-b-sh {
                    display: inline-block;
                    vertical-align: middle;

                    .a-shar {
                        margin: 0;
                        text-align: left;
                    }
                }
            }

            .kr-shared-one {
                .a-shar {
                    a {
                        margin-left: 2px;

                        .kr-social-icons {
                            width: 29px;
                            height: 29px;
                            background: none, url("#{$path-img}new_design/sprite.svg") no-repeat;
                            background-size: inherit;
                            opacity: 1;

                            &.kr-vk {
                                background-position: -137px -248px;
                            }

                            &.kr-ok {
                                background-position: -171px -248px;
                            }

                            &.kr-f {
                                background-position: -207px -248px;
                            }

                            &.kr-m {
                                background-position: -242px -248px;
                            }
                        }

                        &:hover {
                            opacity: 0.8;
                        }
                    }
                }
            }
        }

        .kr-logo-with-descr {
            .a-logo {
                display: inline-block;
                vertical-align: middle;
                margin-right: 4px;
                width: 188px;
                height: 27px;
                background: none, url("#{$path-img}new_design/logo.svg") no-repeat;
            }

            .kr-logo-descriptor {
                display: inline-block;
                vertical-align: middle;
                font-size: 12px;
                line-height: 1.2;
                color: #fff;
            }

            @media #{$max-w-md} {
                margin-bottom: 10px;
            }
        }

        @media #{$max-w-md} {
            text-align: center;

            .kr-shared-block {
                float: none;
            }
        }
    }

    .v-slider-body {
        padding: 10px 4px 10px 0;

        .preview {
            padding: 20px;

            &::-webkit-scrollbar {
                width: 8px;
                height: 8px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 4px;
                background-color: #68b300;
            }

            &::-webkit-scrollbar-track {
                border-radius: 4px;
                box-shadow: inset 0 2px 10px rgba(0, 0, 0, 0.3);
            }

            @media (max-width: 991px) {
                padding: 10px;
            }
        }

        .a-slider-1 {
            position: relative;
            overflow: hidden;
            height: auto;

            .slide {
                overflow: hidden;
                width: 100%;
                height: 0;
                text-align: center;

                &.show {
                    position: relative;
                    z-index: 1;
                    height: auto;
                    min-height: 50px;
                }
            }

            img {
                display: block;
                margin: auto;
                border: 0;
                width: 100%;
                height: auto;
                max-width: 100%;
            }

            .a-pointly {
                position: absolute;
                top: 0;
                z-index: 99;
                display: block;
                height: 100%;
                background: 0;
                opacity: 0;

                &.a-leftpoint {
                    left: 0;
                    width: 40%;
                    cursor: pointer;
                }

                &.a-rightpoint {
                    right: 0;
                    width: 40%;
                    cursor: pointer;
                }
            }
        }

        &.v-slider-body-ppt {
            padding: 0;
        }
    }

    .v-slider-footer {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        border-radius: 0 0 4px 4px;
        padding: 20px 20px 18px;
        background-image: linear-gradient(to right, #eceae1 0%, #eceae1 50%, #eceae1 100%);
        background-color: #e1e4d5;

        .a-pult {
            float: left;
            user-select: none;

            .a-page {
                display: inline-block;
            }

            .v-button-doc-player {
                display: inline-block;
                border-radius: 4px;
                padding-top: 12px;
                width: 79px;
                height: 39px;
                box-shadow: 0 2px 0 rgba(91, 151, 191, 0.7);
                text-align: center;
                background-color: #87d0ff;
                transition: all 200ms linear;
                cursor: pointer;
                user-select: none;

                i {
                    display: inline-block;
                    width: 26px;
                    height: 16px;
                    background: none, url("#{$path-img}new_design/sprite.svg") no-repeat;

                    &.a-bac-top {
                        background-position: -107px -292px;
                    }

                    &.a-for-boot {
                        background-position: -107px -292px;
                        transform: rotate(-180deg);
                    }
                }

                &:hover {
                    transform: scale(1.1);
                }
            }

            @media (max-width: 990px) {
                margin-right: 20px;
            }

            @media (max-width: 580px) {
                margin-right: 0;
                margin-bottom: 25px;
            }
        }

        .v-give-code {
            position: relative;
            float: right;
            border-radius: 4px;
            padding: 6px 20px 6px 60px;
            width: 209px;
            box-shadow: 0 2px 0 rgba(148, 158, 128, 0.1);
            font-weight: 300;
            font-size: 20px;
            color: #718898;
            background-color: #fff;

            i {
                position: absolute;
                left: 0;
                top: 0;
                display: inline-block;
                border-radius: 4px 0 0 4px;
                width: 44px;
                height: 39px;
                background: #d3d3c1 url("#{$path-img}new_design/sprite.svg") no-repeat -218px -282px;
            }
        }

        &.v-slider-footer-ppt {
            .a-pult {
                float: left;

                .a-fast-backward,
                .a-fast-forward {
                    display: inline-block;
                    vertical-align: middle;
                    border-radius: 4px;
                    padding: 4px 4px 0;
                    width: unset;
                    height: unset;
                    box-shadow: 0 2px 0 rgba(91, 151, 191, 0.7);
                    text-align: center;
                    background-color: rgba(135, 208, 255, 0.3);
                    cursor: pointer;
                    user-select: none;

                    i {
                        display: inline-block;
                        width: 32px;
                        height: 27px;
                        background: none, url("#{$path-img}new_design/sprite.svg") no-repeat;
                    }

                    @media #{$max-w-xs} {
                        width: 40px;
                    }
                }

                .a-fast-backward {
                    i {
                        background-position: -144px -293px;
                    }
                }

                .a-fast-forward {
                    i {
                        background-position: -144px -293px;
                        transform: rotate(-180deg);
                    }
                }

                .a-backward,
                .a-backward-l {
                    display: inline-block;
                    vertical-align: middle;
                    border-radius: 4px;
                    padding: 4px 4px 0;
                    width: unset;
                    height: unset;
                    box-shadow: 0 2px 0 rgba(91, 151, 191, 0.7);
                    text-align: center;
                    background-color: #87d0ff;
                    cursor: pointer;
                    user-select: none;

                    i {
                        display: inline-block;
                        width: 16px;
                        height: 27px;
                        background: none, url("#{$path-img}new_design/sprite.svg") no-repeat;
                    }

                    @media #{$max-w-xs} {
                        width: 32px;
                    }
                }

                .a-backward {
                    i {
                        background-position: -191px -293px;
                    }
                }

                .a-backward-l {
                    i {
                        background-position: -191px -293px;
                        transform: rotate(-180deg);
                    }
                }

                .a-page {
                    display: inline-block;
                    vertical-align: middle;
                    margin: 0 16px;
                    font-size: 24px;

                    .a-pages {
                        font-weight: 700;
                    }

                    .a-dalee {
                        font-weight: 300;
                        color: #afafaf;
                    }

                    @media #{$max-w-xs} {
                        margin: 0 9px;
                    }
                }
            }
        }

        @media (max-width: 580px) {
            flex-direction: column;
        }

        @media #{$max-w-xs} {
            padding: 10px;
        }
    }

    .v-slider-wrap {
        .v-slider-bottom-text {
            margin-top: 14px;
            font-weight: 300;
            font-size: 18px;
            line-height: 1;
            text-align: center;
            color: #595959;

            b {
                display: inline-block;
                border-radius: 4px;
                padding: 2px 6px;
                font-weight: 600;
                font-size: 20px;
                line-height: 1;
                text-align: center;
                color: #fff;
                background-color: rgba(120, 150, 167, 0.7);
            }

            span {
                font-weight: 300;
                font-size: 14px;
                color: #595959;
            }

            @media #{$max-w-md} {
                font-size: 16px;
            }
        }

        @media #{$max-w-md} {
            padding: 0 10px 10px;
        }
    }
}

.v-library-commment {
    .a-u-s {
        border: none;
        padding: 22px 30px;

        p {
            &.a-note {
                margin-bottom: 24px;
                font-weight: 300;
                font-size: 26px;
                line-height: 1;
                color: #000;

                span {
                    margin: 0;
                    font-size: 16px;
                    color: #7e7e7e;
                }
            }
        }

        .aa-comment-form {
            .redactor-editor,
            .redactor-box {
                border-radius: 4px;
                background-color: #f5f5f5;

                .redactor-toolbar {
                    border-bottom: 1px solid #eee;
                    border-radius: 4px 4px 0 0;
                    box-shadow: none;
                    background-color: #f5f5f5;

                    li {
                        a {
                            background: none;

                            &:hover {
                                color: #1987c4;
                            }
                        }
                    }
                }

                .redactor-editor {
                    border: none;
                    padding: 16px !important;
                }
            }
        }

        @media #{$max-w-md} {
            padding: 10px;
        }
    }

    .aa-comment-list {
        margin-right: -30px;
        margin-left: -30px;

        .a-comm {
            margin: 0;
            border-top: 1px solid #dedede;
            border-radius: 0;
            padding: 14px 30px;
            background: none;

            .a-comm1 {
                &::after {
                    content: "";
                    display: block;
                    clear: both;
                }

                .dr-author {
                    float: left;

                    b {
                        font-weight: 300;

                        span {
                            font-size: 22px;
                            line-height: 1;
                            color: #888;
                        }
                    }

                    .kr-black-white-icons {
                        &.kr-profile {
                            top: -1px;
                            width: 16px;
                            height: 16px;
                            background: none, url("#{$path-img}new_design/sprite.svg") no-repeat -66px -143px;
                        }
                    }

                    @media #{$max-w-md} {
                        display: block;
                        float: none;
                        margin-bottom: 6px;

                        b {
                            span {
                                font-size: 14px;
                            }
                        }
                    }
                }

                .v-right-date {
                    float: right;
                    color: #898989;

                    .kr-black-white-icons {
                        &.kr-material-date {
                            top: -1px;
                            width: 16px;
                            height: 16px;
                            background: none, url("#{$path-img}new_design/sprite.svg") no-repeat -66px -165px;
                        }
                    }

                    @media #{$max-w-md} {
                        float: none;
                    }
                }
            }

            &:last-child {
                padding-bottom: 0;
            }

            @media #{$max-w-md} {
                padding: 10px;
            }
        }

        @media #{$max-w-md} {
            margin: 0;
        }
    }
}

.v-library-document {
    h1 {
        margin: 0 0 20px;
        border-radius: 4px 4px 0 0;
        padding: 26px 30px;
        font-weight: 600;
        font-size: 32px;
        line-height: 1;
        text-align: left;
        color: #37474f;
        background-color: #fff;

        @media #{$max-w-md} {
            padding: 25px 10px 10px;
        }

        @media #{$max-w-xs} {
            font-size: 29px;
        }
    }

    & h1.v-library-document__title {
        padding: 0;
        line-height: 1.2;
        word-break: break-word;
        text-align: left;
        background-color: unset;

        &:last-child {
            margin-bottom: 0;
        }
    }

    & .dg-wrapper__alert {
        border-bottom: none;
        border-radius: 4px;
    }
}

.v-new-general-info {
    .v-library__item {
        border-bottom: none;

        .v-document-general-info {
            .kr-document-general-info__header {
                border-radius: 4px;
                padding: 8px 10px;
                background-color: #f5f5f5;
            }

            .v-inline-list {
                &.icon-list {
                    align-items: center;
                    margin: 0;

                    li {
                        &.v-author-inside {
                            a {
                                font-weight: 300;
                                font-size: 18px;
                                color: #000;
                            }
                        }
                    }
                }

                &.meta-tags {
                    li {
                        margin-bottom: 4px;
                        border: none;
                        background: none;
                    }
                }
            }
        }
    }

    .v-btn-give-sert {
        display: inline-block;
        border-radius: 4px;
        padding: 4px 12px;
        font-weight: 300;
        line-height: 1;
        text-align: center;
        color: #fff;
        background-color: #5b97bf;
    }
}

.ta-docSearch-blck {
    &:last-child {
        margin-right: 0;
    }

    &:nth-child(2n) {
        @media #{$min-w-md} and #{$max-w-lg} {
            margin-right: 0;
        }
    }

    @media #{$min-w-lg} {
        float: left;
        margin-right: 1.62757%;
        width: 23.77932%;
    }

    @media #{$min-w-md} and #{$max-w-lg} {
        float: left;
        margin-right: 1.62757%;
        margin-bottom: 10px;
        width: 49.18622%;
    }
}

.ta-related-docs {
    display: flex;
    flex-wrap: wrap;

    &__block {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        border: 1px solid #eaeaea;
        border-radius: 4px;
        padding: 16px;
        width: 100%;
        word-break: break-word;

        &:nth-child(3n) {
            @media #{$min-w-sm} {
                margin-right: 0;
            }
        }

        &.ta-related-dop {
            padding: 12px;
            text-align: center;

            a {
                display: block;
                margin-top: 4px;
                text-decoration: underline;
                color: #555;

                img {
                    display: block;
                    margin: 6px auto 0;
                    width: 22px;
                    height: 22px;
                }

                &:hover {
                    text-decoration: none;
                }
            }
        }

        @media #{$min-w-sm} {
            margin-right: 1.69492%;
            width: 32.20339%;
            min-height: 150px;
        }
    }

    &--info {
        margin-top: auto;

        li {
            display: inline-block;
        }
    }
    /* stylelint-disable */
        &--title {
          margin-bottom: 10px;
          font-weight: 300;
          font-size: 16px;
          color: #1987c4;

          @supports (display: -webkit-box) {
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 5;
              overflow: hidden;
          }
      }
      /* stylelint-enable */
}

.v-similar-wrap {
    display: flex;
    flex-wrap: wrap;

    .v-similar {
        overflow: auto;
        margin-bottom: 10px;
        border: 1px solid #eaeaea;
        border-radius: 4px;
        padding: 15px;
        width: 100%;
        min-height: 133px;
        background-color: #fff;

        .kr-recently-viewed__item {
            margin: 0;
        }

        .kr-sidebar-block__content-title {
            overflow: hidden;
            min-height: 66px;
            max-height: 66px;

            span {
                font-weight: 300;
                font-size: 16px;
                color: #1987c4;
            }
        }

        .kr-list {
            li {
                display: inline-block;
            }
        }

        &:nth-child(3n) {
            @media #{$min-w-sm} {
                margin-right: 0;
            }
        }

        &.v-similar-dop {
            padding: 12px 0 11px;
            text-align: center;

            a {
                display: block;
                margin-top: 4px;
                text-decoration: underline;
                color: #555;

                img {
                    display: block;
                    margin: 6px auto 0;
                    width: 22px;
                    height: 22px;
                }

                &:hover {
                    text-decoration: none;
                }
            }
        }

        @media #{$min-w-sm} {
            float: left;
            margin-right: 1.69492%;
            width: 32.20339%;
        }
    }

    .dr-col-4 {
        margin-bottom: 10px;

        &:nth-child(3n) {
            margin-right: 0;
        }
    }
}

.library_button_download {
    margin-bottom: 20px;
}

.v-btn-download {
    position: relative;
    display: inline-block;
    margin: 0 auto;
    border-radius: 4px;
    padding: 6px 20px;
    font-weight: 600;
    font-size: 20px;
    line-height: 1.4;
    text-transform: uppercase;
    color: #fff;
    background-color: #72bb4c;

    i {
        display: none;

        &.v-icon-download {
            background: none, url("#{$img-path}/library/library-download.svg") no-repeat;
        }
    }

    &:hover {
        text-decoration: none;
        color: #fff;
        background-color: #52d65c;
    }
}

.dg-download-lnk {
    display: inline-block;
    font-size: 18px;
    color: #999;

    &__wrapper {
        margin: -16px 0 6px;
        text-align: center;
    }

    &:hover {
        text-decoration: underline;
        color: #999;
    }
}

.v-button-blue {
    display: inline-block;
    margin-bottom: 14px;
    border-radius: 4px;
    padding: 6px 10px 8px;
    font-weight: 300;
    font-size: 22px;
    line-height: 1;
    color: #fff;
    background-color: #5b97bf;
    transition: 0.3s ease-out;

    &:hover {
        color: #fff;
        background-color: #1987c4;
    }
}

.v-search-inside {
    border-bottom: 1px solid #dedede;
    border-radius: 4px;
    padding: 12px 30px 30px;
    background-color: #fff;

    .v-library-filter-search {
        margin-bottom: 0;
    }

    &.v-not-bd-btm {
        border-radius: 4px 4px 0 0;
    }
}

.v-search-inside-title {
    margin-bottom: 16px;
    font-weight: 300;
    font-size: 24px;
    line-height: 1;
    color: #6b6b6b;
}

.v-doc-arch {
    .v-doc-arch-item {
        &:hover {
            border-color: #6e99b2;
            background-color: #6e99b2;

            span {
                color: #fff !important;
            }

            i {
                opacity: 1;
            }
        }
    }

    .v-doc-arch-item {
        margin-bottom: 10px;
        border: 1px solid #e8e8e8;
        border-radius: 6px;
        padding: 10px;
        line-height: 1;
        background-color: #fff;

        .a-span {
            float: right;
            padding: 4px 2px 0 16px;
            font-size: 12px;
            color: #8a8a8a;

            strong {
                font-weight: 300;
                line-height: 1;
            }
        }

        .dr-block-overflow {
            vertical-align: middle;
            overflow: inherit;
            font-weight: 300;
            font-size: 14px;
            color: #37627a;
        }

        article {
            i {
                position: relative;
                margin-left: 4px;
                border-radius: 4px;
                width: 43px;
                height: 19px;
                min-height: 20px;
                font-weight: 400;
                font-style: normal;
                font-size: 12px;
                line-height: 21px;
                text-align: center;
                text-transform: uppercase;
                color: #fff;
                background-color: #ccc;
                opacity: 0.8;

                &.a-doc {
                    background: #a1d9f9;

                    &::before {
                        content: "doc";
                    }
                }

                &.a-docx {
                    background: #caccff;

                    &::before {
                        content: "docx";
                    }
                }

                &.a-pdf {
                    background: #ffbbb0;

                    &::before {
                        content: "pdf";
                    }
                }

                &.a-tpl {
                    background: #cfc7b7;

                    &::before {
                        content: "tpl";
                    }
                }

                &.a-txt {
                    border: 1px solid #b4b4b4;
                    background: transparent;

                    &::before {
                        content: "txt";
                        box-sizing: border-box;
                        color: #b4b4b4;
                    }
                }

                &.a-pptx {
                    background: #ffcbae;

                    &::before {
                        content: "pptx";
                    }
                }

                &.a-ppsx {
                    background: #ebc2b9;

                    &::before {
                        content: "ppsx";
                    }
                }

                &.a-ppt {
                    background: #f9bbbb;

                    &::before {
                        content: "ppt";
                    }
                }

                &.a-pptm {
                    background: #f7bfb0;

                    &::before {
                        content: "pptm";
                    }
                }

                &.a-rar {
                    background: #f4c0f1;

                    &::before {
                        content: "rar";
                    }
                }

                &.a-7z {
                    background: #d6d6d6;

                    &::before {
                        content: "7z";
                    }
                }

                &.a-zip {
                    background: #fbdc93;

                    &::before {
                        content: "zip";
                    }
                }

                &.a-jpg {
                    background: #fadb4b;

                    &::before {
                        content: "jpg";
                    }
                }

                &.a-bmp {
                    background: #bcd5ff;

                    &::before {
                        content: "bmp";
                    }
                }

                &.a-png {
                    background: #abdde8;

                    &::before {
                        content: "png";
                    }
                }

                &.a-psd {
                    background: #b7cee2;

                    &::before {
                        content: "psd";
                    }
                }

                &.a-avi {
                    background: #ffb3b3;

                    &::before {
                        content: "avi";
                    }
                }

                &.a-flv {
                    background: #e2c3c5;

                    &::before {
                        content: "flv";
                    }
                }

                &.a-mp4 {
                    background: #f1c5de;

                    &::before {
                        content: "mp4";
                    }
                }

                &.a-dif {
                    background: #bdebb6;

                    &::before {
                        content: "dif";
                    }
                }

                &.a-xls {
                    background: #cbea81;

                    &::before {
                        content: "xls";
                    }
                }

                &.a-xlsx {
                    background: #a1edc1;

                    &::before {
                        content: "xlsx";
                    }
                }
            }
        }
    }
}

.v-file-choose {
    margin-bottom: 20px;
    border-radius: 4px 4px 0 0;
    padding: 26px 30px;
    font-weight: 300;
    line-height: 1;
    color: #232323;
    background-color: #fff;

    strong {
        display: block;
        margin-bottom: 4px;
    }
}

.v-wb-bw {
    font-weight: 300;

    ul {
        li {
            margin-bottom: 10px;
            font-size: 18px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    p {
        margin-bottom: 10px;
        font-size: 18px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.v-top-side-new-library {
    margin: -10px 0 20px;
    padding-bottom: 40px;
    background: #1a4f6e url("#{$path-img}library/bottom-heade.png");

    .v-top-side-new-library-header {
        margin: 0 0 30px;
        padding: 38px 0;
        background: url("#{$path-img}library/top-head.png"), linear-gradient(to right, #009387 0%, #008cb1 100%);

        .v-top-side-new-title {
            font-weight: 700;
            font-size: 56px;
            line-height: 0.9;
            color: #fff;

            span {
                display: block;
                font-weight: 300;
                font-size: 40px;
            }
        }

        .v-top-side-new-counter-wrap {
            border-radius: 4px;
            padding: 20px;
            background-image: linear-gradient(45deg, #00b7a8 0%, #00a9d5 100%);

            .v-top-side-new-counter,
            .v-top-side-new-counter-text {
                display: inline-block;
                vertical-align: middle;
            }

            .v-top-side-new-counter {
                margin-right: 10px;

                span {
                    position: relative;
                    display: inline-block;
                    vertical-align: top;
                    margin-right: 4px;
                    border-radius: 4px;
                    padding: 4px 6px 5px;
                    font-weight: 300;
                    font-size: 40px;
                    line-height: 1;
                    text-shadow: 0 2px 0 rgba(186, 104, 0, 0.5);
                    color: #fff;
                    background-image: linear-gradient(45deg, #ff7800 0%, #ffc000 100%);
                    background-color: #fffcc5;

                    &:last-child {
                        margin-right: 0;
                    }

                    &.v-top-side-new-counter-dot {
                        margin-right: 13px;

                        &::before {
                            content: "";
                            position: absolute;
                            right: -8px;
                            bottom: 9px;
                            display: block;
                            border-radius: 50%;
                            width: 4px;
                            height: 4px;
                            background-image: linear-gradient(45deg, #ff9c00 0%, #ffd200 100%);
                            background-color: #fffcc5;
                        }
                    }
                }

                @media #{$max-w-xs} {
                    margin-right: 0;

                    span {
                        padding: 2px;
                    }
                }
            }

            .v-top-side-new-counter-text {
                font-weight: 300;
                font-size: 18px;
                color: #fff;

                span {
                    display: inline-block;
                    border-bottom: 1px solid #dccd25;
                    padding: 0 2px;
                    background-image: linear-gradient(to right, #00bd16 0%, #70d615 100%);
                    background-color: #ff9000;
                }

                @media #{$max-w-xs} {
                    font-size: 26px;
                }
            }
        }

        @media #{$max-w-md} {
            margin-bottom: 14px;
            padding: 20px 0;
            text-align: center;

            .v-top-side-new-title {
                margin-bottom: 14px;
                font-size: 40px;

                br {
                    display: none;
                }

                span {
                    display: inline-block;
                    font-size: 36px;
                }
            }
        }
    }

    .v-top-side-sub-title {
        margin-bottom: 30px;

        .v-top-side-sub-title-text,
        .v-top-side-sub-title-btn {
            display: inline-block;
            vertical-align: middle;
            line-height: 1;

            @media (max-width: $width-lg) {
                margin-top: 10px;
            }
        }

        .v-top-side-sub-title-text {
            margin-right: 20px;
            font-weight: 300;
            font-size: 36px;
            color: #fff;

            span {
                display: inline-block;
                border-bottom: 1px solid #ff7800;
                padding: 2px 4px;
                background-image: linear-gradient(to right, #00b7a8 0%, #00a9d5 100%);
                background-color: #00b7a8;
            }
        }

        .v-top-side-sub-title-btn {
            border-radius: 4px;
            padding: 11px 22px;
            font-size: 20px;
            text-align: center;
            color: #000;
            background-image: linear-gradient(to top, #ffe200 0%, #ffe200 100%);
            background-color: #ffe200;

            &:hover {
                background-image: linear-gradient(to right, #fc0 0%, #fffcb3 100%);
            }
        }

        @media (max-width: $width-lg) {
            text-align: center;
        }

        @media #{$max-w-sm} {
            margin-bottom: 0;
        }
    }

    .v-lib-sert-list-wrap {
        display: flex;
        justify-content: space-between;

        .v-lib-sert-top-block {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 11.7%;
            font-weight: 300;
            color: #fff;

            & .img {
                margin: auto 0 2px;
            }

            .v-lib-sert-top-block-title {
                margin-bottom: 4px;
                font-size: 16px;
            }

            .v-lib-sert-top-block-desc {
                margin-bottom: 8px;
                font-size: 12px;
                line-height: 1.25;
                color: #d3e8e7;
            }

            .v-lib-sert-top-block-mat {
                font-size: 12px;

                i {
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 3px;
                    border-radius: 50px;
                    padding: 2px 6px;
                    font-weight: 700;
                    font-style: normal;
                    font-size: 16px;
                    line-height: 1;
                    text-align: center;
                }
            }

            .dg-cert-text {
                display: none;
            }

            &:nth-child(1) {
                & i,
                & .dg-cert-text a {
                    background-image: linear-gradient(45deg, #ff4d20 0%, #f33af5 100%);
                }
            }

            &:nth-child(2) {
                & i,
                & .dg-cert-text a {
                    background-image: linear-gradient(45deg, #8758ff 0%, #a77cff 100%);
                }
            }

            &:nth-child(3) {
                & i,
                & .dg-cert-text a {
                    background-image: linear-gradient(45deg, #485eff 0%, #7791ff 100%);
                }
            }

            &:nth-child(4) {
                & i,
                & .dg-cert-text a {
                    background-image: linear-gradient(45deg, #0a87f8 0%, #37b9f5 100%);
                }
            }

            &:nth-child(5) {
                & i,
                & .dg-cert-text a {
                    background-image: linear-gradient(45deg, #00985d 0%, #00c574 100%);
                }
            }

            &:nth-child(6) {
                & i,
                & .dg-cert-text a {
                    background-image: linear-gradient(45deg, #58a900 0%, #25e100 100%);
                }
            }

            &:nth-child(7) {
                & i,
                & .dg-cert-text a {
                    background-image: linear-gradient(45deg, #ff8a00 0%, #ffc000 100%);
                }
            }

            &:nth-child(8) {
                & i,
                & .dg-cert-text a {
                    background-image: linear-gradient(45deg, #f00 0%, #fc8e00 100%);
                }
            }

            &.dg-done {
                & .img {
                    position: relative;

                    &::before {
                        content: "";
                        position: absolute;
                        left: -6px;
                        top: -6px;
                        border-radius: 50%;
                        width: 30px;
                        height: 30px;
                        background-color: #52d65c;
                    }

                    &::after {
                        content: "✔";
                        position: absolute;
                        left: -6px;
                        top: -6px;
                        width: 30px;
                        height: 30px;
                        font-size: 20px;
                        line-height: 30px;
                        text-align: center;
                    }
                }

                & .dg-hider {
                    display: none;
                }

                & .dg-cert-text {
                    display: block;
                    font-size: 12px;
                    letter-spacing: -0.2px;
                    color: #d3e8e7;

                    & a {
                        display: inline-block;
                        margin-top: 4px;
                        border-bottom: 1px solid #ff7800;
                        border-radius: 0;
                        padding: 2px;
                        font-size: 14px;
                        color: #fff;
                    }
                }
            }
        }

        @media (min-width: 621px) and (max-width: 1210px) {
            flex-wrap: wrap;

            .v-lib-sert-top-block {
                margin-bottom: 20px;
                width: 24%;
            }
        }

        @media (max-width: 620px) {
            flex-wrap: wrap;

            .v-lib-sert-top-block {
                margin-bottom: 20px;
                width: 45%;
            }
        }
    }
}

.v-lib-free-sert {
    border-radius: 4px;
    padding: 23px 21px;
    background-color: #27668b;

    .v-lib-free-sert-title {
        font-weight: 300;
        font-size: 24px;
        color: #fff;

        span {
            border-bottom: 1px solid #ff9000;
            padding: 0 2px;
            color: #000;
            background-image: linear-gradient(to right, #fc0 0%, #fff600 100%);
            background-color: #fc0;
        }

        @media (max-width: $width-lg) {
            font-size: 18px;
        }
    }

    hr {
        display: block;
        margin: 18px 0 20px;
        outline: none;
        border: none;
        width: 100%;
        height: 1px;
        background-color: #628ba3;
    }

    .v-lib-free-sert-desc {
        margin-bottom: 10px;
        padding-left: 10px;
        font-weight: 300;
        font-size: 12px;
        line-height: 1.3;
        color: #fff;

        span {
            display: inline-block;
            margin: 2px 0;
            border-radius: 12px;
            padding: 2px 6px;
            font-weight: 700;
            font-size: 16px;
            background-image: linear-gradient(45deg, #ff4d20 0%, #f33af5 100%);
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .dg-cert-text {
        display: none;
    }

    &.dg-done {
        & .img {
            position: relative;

            &::before {
                content: "";
                position: absolute;
                left: -6px;
                top: -6px;
                border-radius: 50%;
                width: 30px;
                height: 30px;
                background-color: #52d65c;
            }

            &::after {
                content: "✔";
                position: absolute;
                left: -6px;
                top: -6px;
                width: 30px;
                height: 30px;
                font-size: 20px;
                line-height: 30px;
                text-align: center;
                color: #fff;
            }
        }

        & .dg-hider {
            display: none;
        }

        & .dg-cert-text {
            display: block;
            margin-bottom: 10px;
            padding-left: 10px;
            font-size: 12px;
            letter-spacing: -0.2px;
            color: #d3e8e7;

            & a {
                display: inline-block;
                margin-top: 4px;
                border-bottom: 1px solid #ff7800;
                border-radius: 0;
                padding: 2px;
                font-size: 14px;
                color: #fff;
                background-image: linear-gradient(45deg, #ff4d20 0%, #f33af5 100%);
            }
        }
    }

    @media (max-width: $width-lg) {
        padding: 10px;
    }
}

.v-head-l-last {
    @media (max-width: $width-lg) {
        .col-3 {
            display: none;
        }

        .col-9 {
            width: 100%;
        }
    }
}

.v-new-branding-bg {
    span {
        &.empty {
            padding: 20px;

            .v-library-filter-search {
                margin-bottom: 10px;
            }

            .v-library-main-title {
                margin-bottom: 16px;
                font-weight: 300;
                font-size: 16px;
                color: #232323;
            }
        }
    }
}

.dg-library {
    &__rate {
        display: flex;
        flex-direction: column;
        align-items: center;

        &--pop-text {
            margin-bottom: 20px;

            & .dg-title {
                font-weight: 700;
            }

            & li {
                margin-bottom: 6px;

                &:last-child {
                    margin-bottom: 0;
                }

                &:nth-child(n+2) {
                    position: relative;
                    padding-left: 20px;

                    &::before {
                        content: "";
                        position: absolute;
                        left: 6px;
                        top: 8px;
                        border-radius: 50%;
                        width: 4px;
                        height: 4px;
                        background-color: #000;
                    }
                }
            }
        }

        &--stars {
            margin-bottom: 20px;
            width: 30%;
            filter: drop-shadow(0 3px 1px rgba(0, 0, 0, 0.3));
        }

        &--number {
            font-weight: 500;
            color: #3a2;
        }

        &--inpopup {
            display: flex;
            justify-content: center;
            margin-bottom: 10px;
        }

        &--choose {
            height: auto;
            max-width: 102px;
            transform: rotateY(-180deg);

            & .st0 {
                fill: #666;
                stroke: #666;
                transition: 0.2s;
            }

            & .one:hover {
                & .st0 {
                    fill: #ffcf00;
                    transform: translateY(-1px);
                }

                & ~ .five,
                & ~ .four,
                & ~ .tree,
                & ~ .two {
                    & .st0 {
                        fill: #ffcf00;
                    }
                }
            }

            & .two:hover {
                & .st0 {
                    fill: #ffcf00;
                    transform: translateY(-1px);
                }

                & ~ .five,
                & ~ .four,
                & ~ .tree {
                    & .st0 {
                        fill: #ffcf00;
                    }
                }
            }

            & .tree:hover {
                & .st0 {
                    fill: #ffcf00;
                    transform: translateY(-1px);
                }

                & ~ .five,
                & ~ .four {
                    & .st0 {
                        fill: #ffcf00;
                    }
                }
            }

            & .four:hover {
                & .st0 {
                    fill: #ffcf00;
                    transform: translateY(-1px);
                }

                & ~ .five {
                    & .st0 {
                        fill: #ffcf00;
                    }
                }
            }

            & .five:hover {
                & .st0 {
                    fill: #ffcf00;
                    transform: translateY(-1px);
                }
            }
        }

        &--wrapper {
            position: relative;
            z-index: 2;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-bottom: 2px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &--title {
            margin-right: 10px;
        }
    }
}

.dg-padding-top-20 {
    padding-top: 20px;
}

.dg-doc-review {
    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;

        & .dg-title {
            margin-bottom: 4px;
            border-bottom: 1px dashed #eee;
            padding-bottom: 4px;
            font-size: 16px;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &--text {
            @media (max-width: 990px) {
                margin-bottom: 10px;
            }
        }

        &--old-cost {
            display: inline-block;
            margin-right: 4px;
            text-decoration: line-through;
            color: #999;
        }

        &--new-cost {
            color: #f00;
        }

        @media (max-width: 990px) {
            flex-direction: column;
        }
    }
}

.set-doc-review {
    flex-shrink: 0;
    margin-left: 20px;

    @media (max-width: 990px) {
        margin-left: 0;
    }
}

.st_banner-library {
    margin: 0 0 20px;
    padding: 40px 0;
    color: #fff;
    background: #1a4f6e url("#{$img-path}/library/bottom-heade.png");

    &__top {
        padding: 38px 0;
        color: #fff;
        background: url("#{$img-path}/library/top-head.png"), linear-gradient(to right, #009387 0%, #008cb1 100%);

        &--title {
            font-weight: 700;
            font-size: 56px;
            line-height: 0.9;
            color: #fff;

            @media (max-width: 990px) {
                font-size: 40px;
            }
        }

        &--descr {
            font-weight: 300;
            font-size: 40px;
        }

        &--ls {
            display: flex;
            flex-wrap: wrap;
            border-radius: 4px;
            padding: 20px;
            background-image: linear-gradient(45deg, #00b7a8 0%, #00a9d5 100%);
        }

        &--count {
            & span {
                position: relative;
                display: inline-block;
                margin-right: 4px;
                border-radius: 4px;
                padding: 4px 6px 5px;
                font-weight: 300;
                font-size: 40px;
                line-height: 1;
                text-shadow: 0 2px 0 rgba(186, 104, 0, 0.5);
                background-image: linear-gradient(45deg, #ff7800 0%, #ffc000 100%);

                &:last-child {
                    margin-right: 10px;
                }

                @media (max-width: 480px) {
                    padding: 2px;
                }
            }

            & .v-top-side-new-counter-dot {
                margin-right: 13px;

                &::before {
                    content: "";
                    position: absolute;
                    right: -8px;
                    bottom: 9px;
                    display: block;
                    border-radius: 50%;
                    width: 4px;
                    height: 4px;
                    background-image: linear-gradient(45deg, #ff9c00 0%, #ffd200 100%);
                    background-color: #fffcc5;
                }
            }
        }

        &--text {
            font-weight: 300;
            font-size: 18px;

            & span {
                border-bottom: 1px solid #dccd25;
                padding: 0 2px;
                white-space: nowrap;
                background-image: linear-gradient(to right, #00bd16 0%, #70d615 100%);
            }
        }
    }

    &__header {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 30px;
        font-weight: 300;
        font-size: 36px;
        line-height: 1;

        & span {
            display: inline-block;
            margin-right: auto;
            margin-left: 6px;
            border-bottom: 1px solid #ff7800;
            padding: 2px 4px;
            background-image: linear-gradient(to right, #00b7a8 0%, #00a9d5 100%);

            @media (max-width: 990px) {
                margin: 0 0 10px;
            }
        }

        @media (max-width: 990px) {
            flex-direction: column;
            align-items: center;
            text-align: center;
        }
    }

    &__btn {
        border-radius: 4px;
        padding: 11px 22px;
        font-weight: 400;
        font-size: 20px;
        text-align: center;
        color: #000;
        background-image: linear-gradient(to top, #ffe200 0%, #ffe200 100%);

        &:hover {
            color: #000;
            background-image: linear-gradient(to right, #fc0 0%, #fffcb3 100%);
        }
    }

    &__ls {
        border-radius: 4px;
        padding: 22px 20px;
        background-color: #27668b;

        &--title {
            margin-bottom: 30px;
            border-bottom: 1px solid #628ba3;
            padding-bottom: 20px;
            font-weight: 300;
            font-size: 24px;

            & span {
                border-bottom: 1px solid #ff9000;
                padding: 0 2px;
                color: #000;
                background-image: linear-gradient(to right, #fc0 0%, #fff600 100%);
            }
        }

        &--img {
            width: 49%;

            & img {
                vertical-align: bottom;
            }
        }

        &--text {
            padding-left: 14px;
            width: 51%;
            font-weight: 300;
            font-size: 12px;
            line-height: 1.3;

            & .st_title {
                margin-bottom: 10px;
            }

            & .st_descr {
                display: flex;
                flex-direction: column;

                & span {
                    margin: 2px auto 2px 0;
                    border-radius: 12px;
                    padding: 2px 6px;
                    font-weight: 700;
                    font-size: 16px;
                    background-image: linear-gradient(45deg, #ff4d20 0%, #f33af5 100%);
                }
            }
        }

        @media (max-width: 990px) {
            display: none;
        }
    }

    &__rs {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &__list {
        display: flex;
        flex-direction: column;
        width: 13%;
        font-size: 12px;

        &--title {
            margin-bottom: 4px;
            font-size: 16px;
        }

        &--descr {
            margin-bottom: 8px;
            line-height: 1.25;
            color: #d3e8e7;

            & .counter {
                display: inline-block;
                border-radius: 50px;
                padding: 2px 6px;
                font-weight: 700;
                font-size: 16px;
                line-height: 1;
                color: #fff;

                &.count {
                    &-1 {
                        background-image: linear-gradient(45deg, #ff4d20 0%, #f33af5 100%);
                    }

                    &-2 {
                        background-image: linear-gradient(45deg, #8758ff 0%, #a77cff 100%);
                    }

                    &-3 {
                        background-image: linear-gradient(45deg, #485eff 0%, #7791ff 100%);
                    }

                    &-4 {
                        background-image: linear-gradient(45deg, #0a87f8 0%, #37b9f5 100%);
                    }

                    &-5 {
                        background-image: linear-gradient(45deg, #00985d 0%, #00c574 100%);
                    }

                    &-6 {
                        background-image: linear-gradient(45deg, #58a900 0%, #25e100 100%);
                    }

                    &-7 {
                        background-image: linear-gradient(45deg, #ff8a00 0%, #ffc000 100%);
                    }
                }
            }
        }

        &--img {
            margin-top: auto;
            margin-bottom: 4px;

            & img {
                vertical-align: bottom;
            }
        }

        @media (min-width: 621px) and (max-width: 1210px) {
            margin-bottom: 20px;
            width: 24%;
        }

        @media (max-width: 620px) {
            margin-bottom: 20px;
            width: 45%;
        }
    }
}

.st_right-sidebar {
    &__title {
        margin-bottom: 14px;
        font-weight: 700;
    }

    &__img {
        display: flex;
        align-items: center;
        margin-bottom: 14px;

        & img {
            vertical-align: bottom;
            margin: 0 auto;
        }
    }

    &__block {
        border-radius: 4px;
        padding: 6px;
        background: #fff;
    }

    & .st-button {
        width: 100%;
    }

    &--link {
        display: block;
        margin-bottom: 14px;
        color: #000;

        &:hover {
            color: #000;
        }
    }
}

.dg-doc-search {
    & .items {
        padding-top: 20px;

        & span.empty {
            padding: 0;
        }
    }

    & .v-library__item {
        border-top: 1px solid #dedede;
        border-bottom: none;
        padding: 22px 0;

        &:last-child {
            padding-bottom: 0;
        }
    }
}

.st-flip_effect {
    position: relative;
    z-index: 99;
    transform-style: preserve-3d;

    & .st-front {
        backface-visibility: hidden;
        transition: 0.4s;
    }

    & .st-back {
        position: absolute;
        top: 0;
        border: 1px solid #c7f1a6;
        border-radius: 4px;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 1);
        backface-visibility: hidden;
        transform: rotateY(180deg);
        transition: 0.4s;

        & ul {
            overflow-y: scroll;
            height: 100%;

            & li {
                position: relative;
                margin-bottom: 4px;
                border-bottom: 1px solid #eee;
                padding: 0 10px 4px;
                color: #000;

                & a {
                    display: block;
                    text-align: right;
                }

                &:first-child {
                    padding: 6px 10px 4px;
                }

                &:last-child {
                    margin-bottom: 0;
                    border-bottom: none;
                    padding: 0 10px 6px;
                }
            }
        }
    }

    &:hover {
        & .st-front {
            transform: rotateY(180deg);
        }

        & .st-back {
            transform: rotateY(0deg);
        }
    }
}

.dg-mat-rate {
    padding: 4px 10px;
    background-image: linear-gradient(to right, #feffce, #fff);

    &__title {
        color: #888;
    }

    &__value {
        display: inline-block;
        margin: 0 4px;
        font-weight: 700;
    }

    &__gray {
        font-weight: 300;
        color: #888;
    }

    &__wrapper {
        padding-top: 10px;

        @media (max-width: 990px) {
            margin-bottom: 10px;
            padding-top: 0;
        }
    }
}

.dg-search__btn {
    display: inline-block;
    border-radius: 4px;
    padding: 2px 30px 4px;
    font-weight: 300;
    font-size: 22px;
    color: #fff;
    background-color: #00c8f2;
    transition: 0.2s ease-out;

    &:hover {
        color: #fff;
        background-color: adjust-color(#00c8f2, $red: 30, $green: 30, $blue: 30);
    }

    &--wrapper {
        padding: 10px 0 0;
        text-align: right;
    }
}

.dg-course-banner-direction {
    display: flex;
    flex-direction: column;
}

.dg-course-search-teaser {
    margin-bottom: 20px;

    &__ts {
        border-radius: 4px 4px 0 0;

        &--wrapper {
            border-radius: 4px 4px 0 0;
            padding: 10px 30px;
            background-color: #fff;

            & .name {
                word-break: break-word;
            }

            & p {
                font-weight: 600;
                font-size: 20px;
                text-align: center;
                color: #f00;
            }

            & .sale {
                display: block;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &__bs {
        & .v-new-site-courses-search-wrap {
            border-radius: 4px;
            padding: 10px;
            background: linear-gradient(to right, #007980 0%, #008cb1 100%);

            & .v-contact-us-main-right-title {
                margin-bottom: 10px;
                font-weight: 300;
                font-size: 24px;
                color: #fff;
            }

            & .v-new-site-courses-bottom-block {
                display: flex;
                margin-bottom: 10px;

                & b.dg-icon {
                    display: inline-block;
                    margin-right: 6px;
                    border: 1px solid rgba(255, 255, 255, 0.3);
                    border-radius: 50%;
                    padding: 4px;
                    width: 41px;
                    height: 41px;
                    min-width: 41px;
                    background-color: rgba(245, 245, 245, 0.3);

                    & svg {
                        vertical-align: bottom;
                        width: 100%;
                        height: auto;
                    }
                }

                & i {
                    display: inline-block;
                    margin-right: 6px;
                    border: 1px solid rgba(255, 255, 255, 0.3);
                    border-radius: 50%;
                    width: 41px;
                    height: 41px;
                    min-width: 41px;
                    background: rgba(245, 245, 245, 0.3) url("#{$path-img}new_design/sprite.svg") no-repeat;

                    &.v-new-site-courses-bottom-block-icon1 {
                        background-position: -58px 4px;
                    }

                    &.v-new-site-courses-bottom-block-icon2 {
                        background-position: -58px -32px;
                    }

                    &.v-new-site-courses-bottom-block-icon3 {
                        background-position: -58px -64px;
                    }
                }

                & a.v-new-site-courses-bottom-block-text {
                    display: inline-block;
                    vertical-align: middle;
                    max-width: 80%;
                    font-weight: 300;
                    font-size: 16px;
                    color: #fff000;

                    span {
                        border: 1px solid rgba(255, 222, 0, 0.5);
                        border-radius: 4px;
                        padding: 0 4px;
                        font-weight: 600;
                        font-size: 12px;
                        color: #fff;
                    }

                    & span {
                        display: inline-block;

                        &.dg-price {
                            border: none;
                            padding: 0;
                            font-weight: 400;
                            font-size: 14px;
                            color: #fff;
                        }
                    }
                }
            }

            & .v-new-site-courses-search-block {
                position: relative;
                border-radius: 4px;
                background-color: transparent;

                & input {
                    appearance: textfield;
                    outline: none;
                    border: none;
                    border-radius: 4px;
                    padding: 0 0 2px 10px;
                    width: 100%;
                    min-height: 36px;
                    font-weight: 300;
                    font-size: 18px;
                    color: #0b6f8d;

                    @media (max-width: 480px) {
                        margin-bottom: 10px;
                        width: 100%;
                    }
                }

                & button {
                    position: absolute;
                    right: 0;
                    top: 0;
                    margin: 3px 3px 4px 0;
                    border-radius: 4px;
                    padding: 4px 10px;
                    font-weight: 700;
                    font-size: 16px;
                    color: #fff;
                    background-color: #a4c9d6;
                    transition: 0.3s ease-out;

                    @media (max-width: 480px) {
                        position: relative;
                        float: none;
                        margin: 0;
                        border-radius: 0;
                        width: 100%;
                    }
                }

                & .v-search-result {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 100%;
                    z-index: 99;
                    margin-top: 10px;
                    border-radius: 3px;
                    background-color: #fff;

                    & .v-search-result__none {
                        display: block;
                        padding: 20px;

                        a {
                            text-decoration: underline;
                            color: #ff7800;

                            &:hover {
                                text-decoration: none;
                            }
                        }
                    }

                    & ul {
                        li {
                            a {
                                display: block;
                                width: 100%;
                                font-size: 14px;
                                text-decoration: none;
                                color: #445d74;

                                &:hover {
                                    background-color: rgba(191, 219, 234, 0.5);
                                }
                            }

                            &.v-search-result__header {
                                border-bottom: 1px dashed #9ad0cb;
                                font-weight: 600;
                                font-size: 18px;
                                color: #20746d;
                            }

                            &.v-search-result__footer {
                                border-top: 1px dashed #9ad0cb;
                            }

                            &.v-search-result__item {
                                a {
                                    & .dg-cost {
                                        color: #ff2f2f;
                                    }
                                }
                            }
                        }

                        &:nth-child(2) {
                            padding-top: 0;
                        }
                    }
                }
            }

            & .v-new-site-courses-search-block {
                margin-top: 0;
            }

            @media (max-width: 768px) {
                position: relative;
            }
        }
    }
}

.dg-sb-course {
    margin-bottom: 20px;
    border-radius: 4px;
    padding: 10px;
    box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.2);
    background: #fff;

    &__img {
        display: block;
        overflow: hidden;
        margin: -10px -10px -26px;
        border-radius: 4px 4px 0 0;
        color: #000;
        transition: all 0.2s ease-in;

        & img {
            vertical-align: bottom;
        }

        &:hover {
            color: #000;
            filter: brightness(1.1);
        }
    }

    &__header {
        position: relative;
        margin-bottom: 4px;
        font-size: 16px;
    }

    &__info {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    &__category {
        display: inline-block;
        margin-bottom: 10px;
        border-radius: 4px;
        padding: 2px 6px;
        font-size: 12px;
        line-height: 1.2;
        background-color: #f5f5f5;

        &.dg-type {
            margin-bottom: 6px;
            background-color: rgba(250, 228, 2, 0.8);
        }
    }

    &__btn {
        display: block;
        border-radius: 4px;
        padding: 6px 10px;
        text-align: center;
        color: #fff;
        background-color: #3a2;
    }
}

.ta-material {
    &__rs {
        float: right;
        margin: 0 0 0 20px;
        max-width: 130px;

        img {
            vertical-align: bottom;
        }

        @media #{$max-w-md} {
            margin: 0 0 10px 4px;
            max-width: 100px;
        }
    }

    &__wrapper {
        position: relative;
        border-bottom: 1px solid #dedede;
        padding: 30px 30px 20px;
        background: #fff;

        .kr-show-all {
            display: inline-block;
            margin-bottom: 10px;
        }

        &:last-child {
            margin-bottom: 20px;
            border-bottom: none;
            border-radius: 0 0 4px 4px;
        }

        @media #{$max-w-md} {
            padding: 10px;
        }
    }

    &__btn {
        align-items: center;

        a {
            color: #666;

            &.st-button {
                @media #{$max-w-lg} {
                    &:last-child {
                        margin-bottom: 10px;
                    }
                }
            }
        }

        &-dop {
            margin-left: auto;

            a {
                @media #{$max-w-lg} {
                    display: inline-block;
                    margin-top: 10px;

                    &:nth-child(n+2) {
                        margin-left: 10px;
                    }
                }
            }

            @media #{$max-w-lg} {
                margin-left: 0;
            }
        }

        @media #{$max-w-lg} {
            align-items: flex-start;
        }
    }

    &__list {
        margin-bottom: 10px;

        &--item {
            display: inline-block;
            margin-right: 10px;

            & i {
                margin-right: 4px;
            }

            &:last-child {
                margin-right: 0;
                margin-bottom: 0;
            }

            & a,
            & span {
                color: #888;
            }

            @media #{$max-w-xs} {
                margin-bottom: 6px;
                margin-left: 0;

                &:first-child {
                    display: block;
                }
            }
        }

        @media #{$min-w-sm} {
            display: flex;
        }
    }

    &__tags {
        display: flex;
        flex-wrap: wrap;

        & a {
            margin-right: 10px;
            margin-bottom: 10px;
            border-radius: 4px;
            padding: 4px 12px;
            font-weight: 300;
            font-size: 12px;
            line-height: 1;
            color: #1987c4;
            background-color: #e0f1fd;
            transition: 0.3s ease-out;

            &:hover {
                color: #e0f1fd;
                background-color: #1987c4;
            }
        }

        @media (max-width: 991px) {
            margin-bottom: 0;
        }
    }

    &__title {
        position: relative;
        display: block;
        margin-bottom: 10px;
        font-weight: 400;
        font-size: 18px;
        line-height: 1;
        color: #1987c4;

        &::before {
            position: relative;
            top: -4px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            padding: 0 4px;
            min-width: 42px;
            min-height: 22px;
            font-weight: 400;
            font-size: 12px;
            line-height: 1;
            text-transform: uppercase;
            color: #fff;
        }
    }

    &--info-preuser {
        margin-bottom: 14px;
    }

    &--info-pre {
        span {
            color: map-get($kr-color-grey, "default-light");
        }

        &:last-child {
            margin-bottom: 10px;
        }
    }
}

.st-library-filter {
    &__select {
        & .st-input {
            width: 100%;
        }

        &--title {
            display: block;
            margin: 0 0 8px;
            font-weight: 600;
            font-size: 16px;
            color: #284714;
        }
    }

    &__title {
        display: block;
        margin-bottom: 10px;
        font-weight: 300;
        font-size: 20px;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;

        & li {
            margin: 0 6px 6px 0;

            & a {
                display: block;
                border-radius: 4px;
                padding: 4px 6px;
                line-height: 1;
                color: #e1f4ff;
                background-color: #6e99b2;

                &:hover {
                    color: #fff;
                }

                &.active {
                    color: #fff;
                    background-color: #72bb4c;
                }
            }

            &:last-child {
                margin-right: 0;
                margin-bottom: 0;
            }
        }
    }

    &__statistic {
        color: #76767a;
        line-height: 18px;
    }

    &__free-materials {
        margin-top: 18px;
    }
}

.dg-marked {
    font-weight: 600;
}

.v-doc {
    &::before {
        content: "doc";
        background-color: #a1d9f9;
    }
}

.v-docx {
    &::before {
        content: "docx";
        background-color: #caccff;
    }
}

.v-pdf {
    &::before {
        content: "pdf";
        background-color: #ffbbb0;
    }
}

.v-tpl {
    &::before {
        content: "tpl";
        background-color: #cfc7b7;
    }
}

.v-txt {
    &::before {
        content: "txt";
        border: 1px solid #b4b4b4;
        box-sizing: border-box;
        color: #b4b4b4;
        background-color: transparent;
    }
}

.v-pptx {
    &::before {
        content: "pptx";
        background-color: #ffcbae;
    }
}

.v-ppsx {
    &::before {
        content: "ppsx";
        background-color: #ebc2b9;
    }
}

.v-ppt {
    &::before {
        content: "ppt";
        background-color: #f9bbbb;
    }
}

.v-pptm {
    &::before {
        content: "pptm";
        background-color: #f7bfb0;
    }
}

.v-rar {
    &::before {
        content: "rar";
        background-color: #f4c0f1;
    }
}

.v-7z {
    &::before {
        content: "7z";
        background-color: #d6d6d6;
    }
}

.v-zip {
    &::before {
        content: "zip";
        background-color: #fbdc93;
    }
}

.v-jpg {
    &::before {
        content: "jpg";
        background-color: #fadb4b;
    }
}

.v-bmp {
    &::before {
        content: "bmp";
        background-color: #bcd5ff;
    }
}

.v-png {
    &::before {
        content: "png";
        background-color: #abdde8;
    }
}

.v-psd {
    &::before {
        content: "psd";
        background-color: #b7cee2;
    }
}

.v-avi {
    &::before {
        content: "avi";
        background-color: #ffb3b3;
    }
}

.v-flv {
    &::before {
        content: "flv";
        background-color: #e2c3c5;
    }
}

.v-mp4 {
    &::before {
        content: "mp4";
        background-color: #f1c5de;
    }
}

.v-dif {
    &::before {
        content: "dif";
        background-color: #bdebb6;
    }
}

.v-xls {
    &::before {
        content: "xls";
        background-color: #cbea81;
    }
}

.v-xlsx {
    &::before {
        content: "xlsx";
        background-color: #a1edc1;
    }
}

.dg-course-search {
    &__btn {
        position: relative;
        display: inline-block;
        border-radius: 4px;
        padding: 6px 20px;
        color: #fff;
        background-color: #72bb4c;

        & .dg-arrow {
            display: none;
        }

        &:hover {
            color: #fff;
            background-color: #52d65c;
        }

        @media (max-width: 768px) {
            width: 100%;
            text-align: center;

            & a {
                display: block;
            }
        }
    }
}

@keyframes dgBtnAnimate {
    20% {
        transform: none;
    }

    22.5% {
        text-rendering: auto;
        transform: rotate(5deg);
    }

    25% {
        transform: rotate(0);
    }

    27.5% {
        transform: rotate(-5deg);
    }

    30% {
        transform: rotate(0);
    }

    32.5% {
        transform: rotate(5deg);
    }

    35% {
        transform: rotate(0);
    }

    37.5% {
        transform: rotate(-5deg);
    }

    40% {
        transform: rotate(0);
    }
}

.st-institute-libery {
    text-align: left;

    &__header {
        display: flex;
        margin-bottom: 10px;

        &--link {
            display: inline-block;
            margin: 0 10px;

            @media (max-width: 991px) {
                margin: 0;
            }
        }

        &--right-check {
            margin-left: auto;
        }

        &--lable {
            margin-right: auto;
            font-weight: 600;
        }

        @media (max-width: 990px) {
            flex-direction: column;
        }
    }
}

.dg-library-main-banner {
    display: block;
    margin-bottom: 20px;

    &__head {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        background-color: #26252f;

        & svg {
            margin-right: 10px;
        }

        &--text {
            font-size: 16px;
            text-align: center;
            color: #fff;

            @media (max-width: 360px) {
                & br {
                    display: none;
                }
            }
        }
    }

    &__body {
        padding: 10px;
        box-shadow: inset 0 2px 50px -10px rgba(0, 0, 0, 0.8);
        font-weight: 700;
        font-size: 26px;
        line-height: 1.2;
        text-align: center;
        color: #fff;
        background-image: linear-gradient(to right bottom, #53535b 40%, #414046, #555459, #4f494d, #413c3d, #999);
    }

    &__footer {
        border: 2px solid #333;
        padding: 10px;
        text-align: center;
        background-color: #fefaeb;

        @media (min-width: 991px) {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    &__sale {
        border-radius: 4px;
        background-color: #f9e08b;

        &--head {
            font-weight: 800;
            font-size: 18px;
            line-height: 1;
            color: #f25001;
        }

        &--date {
            font-weight: 600;
            font-size: 11px;
            color: #000;
        }

        &--value {
            margin-bottom: 10px;
            font-weight: 600;
            font-size: 16px;
            color: #000;

            @media (min-width: 991px) {
                margin: 0 10px;
            }
        }

        &--btn {
            border-radius: 4px;
            padding: 10px;
            color: #fff;
            background-color: #f25001;
        }

        @media (min-width: 991px) {
            clip-path: polygon(0 0, 0 100%, calc(100% - 10px) 100%, 100% 50%, calc(100% - 10px) 0);
            padding: 6px 20px 6px 6px;
        }

        @media (max-width: 990px) {
            margin-bottom: 10px;
            padding: 10px;
        }
    }

    &:hover .dg-library-main-banner__sale--btn {
        animation: dgBtnAnimate 1.5s 1;
    }
}

.kr-shared-one {
    & .dg-social-icons {
        margin: 0;
    }
}

.dg-course-search {
    position: relative;
    padding: 20px;
    background: linear-gradient(to right, #007980, #008cb1);

    &__row-wrapper {
        display: flex;
        border-radius: 4px 6px 6px 4px;
        padding: 2px;
        background-color: #fff;
    }

    &__input {
        flex-grow: 1;
        margin-right: 4px;
        border: none;
        padding: 2px 2px 2px 10px;
        min-width: 30px;
        font-weight: 300;
        font-size: 18px;
        color: #0b6f8d;

        &--btn {
            border-radius: 4px;
            padding: 6px 24px;
            font-weight: 700;
            font-size: 16px;
            color: #fff;
            background-color: #a4c9d6;
            transition: 0.3s ease-out;

            &:hover {
                background-color: #5581a0;
            }
        }
    }

    &__result {
        &:not(:last-child) {
            margin-bottom: 20px;
        }

        & a {
            display: block;
            padding: 2px 10px;
            font-size: 14px;
            text-decoration: none;
            color: #445d74;

            &:hover {
                background-color: #dfedf4;
            }
        }

        & .dg-cost {
            color: #ff2f2f;
        }

        &--wrapper {
            position: absolute;
            left: 0;
            right: 0;
            top: 100%;
            z-index: 99;
            border-radius: 0 0 4px 4px;
            padding: 20px 10px;
            box-shadow: 0 10px 30px -10px rgba(0, 0, 0, 0.6);
            background-color: #fff;

            &.fix {
                overflow-y: auto;
                border-radius: 4px;
                padding: 4px 0 6px;

                @media (min-width: 769px) {
                    margin-top: -28px;
                    max-height: 50vh;
                }
            }
        }

        &--header {
            margin: 0 10px 10px;
            border-bottom: 1px dashed #9ad0cb;
            padding-bottom: 10px;
            font-weight: 600;
            font-size: 18px;
            color: #20746d;
        }

        &--footer {
            text-align: right;
        }

        &--none {
            padding: 20px;

            & a {
                text-decoration: underline;
                color: #ff7800;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

.a-namb {
    display: block;
    margin-bottom: 10px;
    font-size: 12px;
    color: #b8b8b8;

    span {
        border-radius: 50%;
        padding: 3px 6px;
        width: 10px;
        height: 10px;
        font-style: normal;
        color: $f;
        background: $d;
    }
}

.a-txt {
    font-size: 12px;
}

.preview {
    overflow: auto;
    max-height: 480px;
}

.a-pad {
    @media (max-width: $width-xs) {
        max-height: calc(60vh - 80px);
    }
}

.a-dog {
    margin-bottom: 20px;
    background: #fff;
}

// @todo: ЧТО ЭТО???
.a-dogs {
    sup,
    sub {
        vertical-align: super !important;
        font-size: smaller !important;
        line-height: 150% !important;
    }

    i {
        width: auto !important;
        height: auto !important;
        background: none !important;
    }

    p,
    ul,
    ol {
        font-size: inherit !important;
        line-height: inherit !important;
        color: inherit !important;
        background: none !important;
    }
}

.st-library-popup {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 999999;
    background-color: rgba(255, 255, 255, 0.95);

    &__wrapp {
        position: absolute;
        top: 10%;
    }

    &__arrow {
        position: relative;
        clip-path: polygon(40% 0%, 40% 20%, 100% 20%, 100% 80%, 40% 80%, 40% 100%, 0% 50%);
        margin-bottom: 40px;
        width: 270px;
        height: 140px;
        background-image: linear-gradient(to right top, #469501, #95e825);
        animation: arrow 2.5s ease-in-out infinite;
    }

    &__text {
        width: 270px;
        font-weight: 800;
        font-size: 24px;
        text-align: center;
        text-shadow: 0 2px 2px rgba(69, 158, 48, 0.1);
        animation: btn-animation 2.5s ease-in-out infinite;
    }
}

// TODO delete rank after add new(2021) materials styles for all
.v-slider-new .v-slider-footer {
    justify-content: space-between;
}

.rank {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.rank__rating {
    display: flex;
    align-items: center;
}

.rank__title {
    margin-right: 10px;
}

.rank__number {
    color: #2bae0d;
}

.rank__apprise {
    display: flex;
    align-items: center;
}

.rank__img {
    fill: #666;
    stroke: #666;
    transition: 0.2s;
}

/* ---- Logic of working stars hover behavior ---- */
.rank__stars {
    height: auto;
    max-width: 102px;
    transform: rotateY(-180deg);

    .rank__star--one:hover {
        .rank__img {
            fill: #ffcd00;
        }

        & ~ .rank__star--five,
        & ~ .rank__star--four,
        & ~ .rank__star--tree,
        & ~ .rank__star--two {
            .rank__img {
                fill: #ffcd00;
            }
        }
    }

    .rank__star--two:hover {
        .rank__img {
            fill: #ffcd00;
        }

        & ~ .rank__star--five,
        & ~ .rank__star--four,
        & ~ .rank__star--tree {
            .rank__img {
                fill: #ffcd00;
            }
        }
    }

    .rank__star--tree:hover {
        .rank__img {
            fill: #ffcd00;
        }

        & ~ .rank__star--five,
        & ~ .rank__star--four {
            .rank__img {
                fill: #ffcd00;
            }
        }
    }

    .rank__star--four:hover {
        .rank__img {
            fill: #ffcd00;
        }

        & ~ .rank__star--five {
            .rank__img {
                fill: #ffcd00;
            }
        }
    }

    .rank__star--five:hover {
        .rank__img {
            fill: #ffcd00;
        }
    }
}

@keyframes arrow {
    50% {
        transform: translateX(-90px);
    }
}

@keyframes btn-animation {
    40% {
        text-shadow: 0 2px 5px rgba(69, 158, 48, 0.7);
    }
}

.st-yandex {
    &__wrapper {
        margin: 0 -30px 20px;
        padding: 20px 30px;
        background-color: #f5f6f6;

        & input[type=checkbox].st-input {
            margin-left: 6px;

            & ~ label {
                &::before {
                    border: 1px solid #000;
                    width: 14px;
                    height: 14px;
                    background-color: #fff;
                }

                &::after {
                    font-size: 14px;
                    color: #000;
                }
            }
        }

        & label {
            font-size: 18px;
        }

        & .st-input__flex-revert {
            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }
    }

    &__title {
        margin-bottom: 10px;
        padding-top: 20px;
        font-weight: 600;
        font-size: 24px;
        line-height: 1.2;
        text-align: center;
    }

    &__btn {
        display: inline-block;
        padding: 10px 20px;
        text-align: center;
    }
}

div.bonus-bill-popup {
    position: relative;

    &::after {
        content: "";
        position: absolute;
        right: -23px;
        top: -15px;
        width: 25px;
        height: 25px;
        background-color: #fff;
    }

    p.bonus-bill-popup__name {
        margin-bottom: 16px;
        font-size: 22px;

        b {
            font-weight: 700;
        }

        span {
            font-weight: 700;
            color: #ff3d00;
        }

        &--medium {
            font-size: 18px;
        }
    }

    p.bonus-bill-popup__text {
        margin-bottom: 16px;
        font-size: 16px;

        b {
            font-weight: 600;
        }

        span {
            font-weight: 700;
            color: #ff3d00;
        }

        &--big {
            font-size: 18px;
        }
    }

    p.bonus-bill-popup__excretion {
        margin-bottom: 16px;
        font-weight: 600;
        font-size: 14px;
        color: #ff3d00;

        span {
            text-decoration: line-through;
        }
    }

    a.bonus-bill-popup__btn {
        display: inline-block;
        margin-top: 4px;
        margin-bottom: 20px;
        border-radius: 4px;
        padding: 16px 25px 17px;
        box-shadow: 0 0 30px rgba(255, 222, 0, 0.35), inset 0 -2px 2px rgba(0, 0, 0, 0.25);
        font-weight: 600;
        font-size: 14px;
        line-height: 123%;
        color: #000;
        background: #ffde00;
        transition-property: box-shadow;
        transition-duration: 0.5s;

        &:hover {
            box-shadow: 0 0 30px #edd00d, inset 0 -2px 2px rgba(0, 0, 0, 0.25);
        }

        &--mb {
            margin-bottom: 0;
        }
    }

    p.bonus-bill-popup__close {
        margin-right: -10px;
        margin-bottom: -10px;
        text-align: right;

        a {
            font-style: italic;
            font-size: 12px;
            line-height: 130%;
            color: #a7a7ab;
        }
    }
}

div.black-friday-popup {
    position: relative;
    border-radius: 4px;
    padding-top: 20px;
    text-align: center;
    background-color: #181818;

    .black-friday-popup__subtitle {
        margin: 15px 0 12px;
        padding: 0 15px;
        font-weight: 700;
        font-size: 24px;
        line-height: 115%;
        color: #fff;

        span {
            color: #f60;
        }
    }

    .black-friday-popup__title {
        margin: 0 0 20px;
        padding: 0 15px;
        font-weight: 700;
        font-size: 32px;
        text-align: center;
        color: #fff;
    }

    .black-friday-popup__btn {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin: 0 0 40px;
        border-radius: 4px;
        padding: 16px 0;
        width: 100%;
        max-width: 390px;
        box-shadow: 0 0 30px rgba(255, 222, 0, 0.35), inset 0 -2px 2px rgba(0, 0, 0, 0.25);
        font-weight: 600;
        font-size: 14px;
        line-height: 120%;
        color: #000;
        background: #ffde00;
        transition-property: box-shadow;
        transition-duration: 500ms;

        &:hover {
            box-shadow: 0 0 30px #edd00d, inset 0 -2px 2px rgba(0, 0, 0, 0.25);
        }
    }

    .black-friday-popup__bottom {
        border-radius: 0 0 4px 4px;
        padding: 4px 0;
        font-weight: 700;
        font-size: 16px;
        line-height: 120%;
        color: #000;
        background: #f0f0f0;
    }

    .black-friday-popup__discount {
        position: absolute;
        left: -45px;
        top: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 90px;
        height: 90px;
        font-weight: 600;
        font-size: 16px;
        line-height: 110%;
        text-align: center;
        color: #fff;
        background-color: #f60;

        &::after {
            content: "";
            position: absolute;
            left: 65%;
            top: 100%;
            width: 70px;
            height: 67px;
            background: url("#{$path-img}/arrow-black-friday.svg") left top no-repeat;
        }

        div {
            max-width: 80px;
        }

        span {
            font-weight: 700;
        }
    }

    .black-friday-popup__close {
        position: absolute;
        right: 8px;
        top: 8px;
    }
}

.st-library-filter__button-close {
    display: none;
    margin-top: 15px;

    @media #{$max-w-sm} {
        display: flex;
        width: 100%;
    }
}

@media (max-width: 722px) {
    div.black-friday-popup {
        .black-friday-popup__discount {
            position: static;
            margin: 0 auto;
            width: 100%;
            height: auto;
            background-color: transparent;

            &::after {
                display: none;
            }

            div {
                max-width: 100%;
            }

            span {
                color: #f60;
            }
        }

        .black-friday-popup__subtitle {
            margin: 8px 0 12px;
        }
    }
}
