$lineColor: #ebebeb;
$black: #000;
$colorText: #333;
$bgBtn: #ffcd00;
$shadowBtn: #d6ac01;
$red: #f00;
$colorAgreement: #777;

.old-browsers {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -999;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    background-color: #000;
    overscroll-behavior: none;
}

.old-browsers--active {
    z-index: 9999999;
    display: block !important;
}

.old-browsers__center {
    min-height: 100%;
}

.old-browsers__block {
    position: relative;
    margin: 25px auto;
    border-radius: 6px;
    padding: 20px 70px;
    max-width: 600px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    background-color: #fff;
}

.old-browsers__close {
    position: absolute;
    right: 10px;
    top: 10px;
    margin: 0;
    outline: none;
    padding: 0;
    width: 32px;
    height: 32px;
    background: none;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
}

.old-browsers__top {
    position: relative;
    border-bottom: 1px solid $lineColor;
    padding: 30px 0 30px 76px;

    a {
        display: block;
    }

    img {
        position: absolute;
        left: 0;
        top: 35px;
    }
}

.old-browsers__title {
    margin: 0 0 10px;
    font-weight: 700;
    font-size: 20px;
    line-height: 1.4;
    color: $black;
}

.old-browsers__text {
    margin: 10px 0;
    font-size: 16px;
    line-height: 1.375;
    color: $colorText;
}

.old-browsers__middle {
    overflow: hidden;
    border-bottom: 1px solid $lineColor;
    padding: 55px 0;
    text-align: center;
}

.old-browsers__left {
    float: left;
}

.old-browsers__right {
    float: right;
}

.old-browsers__left,
.old-browsers__right {
    width: 50%;

    a {
        display: block;
    }
}

.old-browsers__link {
    display: block;
    margin: 0 auto;
    border-radius: 2px;
    padding: 6px 0;
    width: 145px;
    box-shadow: 0 2px 0 $shadowBtn;
    font-weight: 700;
    font-size: 18px;
    line-height: 1.35;
    color: $black;
    background: $bgBtn;
    transition-property: background-color;
    transition-duration: 500ms;

    &:hover {
        color: $black;
        background: lighten($bgBtn, 15%);
    }
}

.old-browsers__bottom {
    display: flex;
    justify-content: center;
    overflow: hidden;
    margin: 0 10px;
    padding: 15px 0 0;
    font-size: 9px;
    line-height: 1.4;
}

.old-browsers__yandex {
    float: left;
    margin-right: 20px;
    width: 85px;
    text-align: right;

    > span {
        display: block;
        font-weight: 700;

        span {
            color: $red;
        }
    }
}

.old-browsers__agreement {
    color: $colorAgreement;

    a {
        text-decoration: underline;
        color: $colorAgreement;

        &:hover {
            text-decoration: none;
        }
    }
}

@media (max-width: 680px) {
    .old-browsers__middle {
        padding: 25px 0;
    }

    .old-browsers__top {
        padding-bottom: 40px;
    }

    .old-browsers__block {
        padding: 20px 15px;
        width: calc(100% - 30px);
    }
}
